
import InputText from '@/components/share/input-text.vue'
import modalWarning from '@/components/modal/modalWarning.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MainNavbar',
  components: {
    'input-text': InputText,
    'modal-warning': modalWarning,
  },
  data() {
    return {
      isLogin: true,
      isActiveMenu: false,
      isActiveLogin: false,
      login: {
        email: '',
        password: '',
      },
      isEmail: false,
      isPassword: false,
      syncUserModalShow: false,
      userOAuth: {},
      oauthConfig: {
        clientId: process.env.NUXT_ENV_OAUTH_CLIENT_ID,
        clientSecret: process.env.NUXT_ENV_OAUTH_CLIENT_SECRET,
        grantType: 'authorization_code',
        redirect_url: `${process.env.NUXT_ENV_HOST_URL}register`,
      },
      toast: 0,
      modalWarning: false,
      warningMessages: [],
    }
  },
  computed: {
    ...mapGetters('cart', ['cartCount']),
    ...mapGetters(['routeHistory']),
    ...mapGetters('user', ['profileType']),
    registerUri: function () {
      console.log('registerUri')
      let redirect_url = encodeURIComponent(this.oauthConfig.redirect_url)
      return `${process.env.NUXT_ENV_BASE_URL_OAuth}authentication/signup?client_id=${this.oauthConfig.clientId}&redirect_uri=${redirect_url}&scopes=email,default`
    },
    isEprocurementUser() {
      return this.$utils.isValid(this.profileType) &&
        this.profileType === 'eprocurement'
        ? true
        : false
    },
  },
  methods: {
    ...mapActions('authen', ['setAuthToken', 'signout']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', ['setUserProfile', 'setUserInfo']),
    onHiddenAll() {
      this.isActiveMenu = false
      this.isActiveLogin = false
    },
    async validateBeforeSubmit() {
      this.$refs.loginForm.validate().then(async (result) => {
        if (result) await this.submitLogin()
      })
    },
    async submitLogin() {
      const password = this.$utils.cryptoJS(this.login.password)
      // console.log(this.login.password)
      let login = Object.assign({}, this.login)
      login.password = password
      const responsed = await this.$authenProvider.signInBasicV2(login)
      if (responsed.status === 201) {
        console.log('responsed: ', responsed)
        if (responsed.data.is_sync_user) {
          this.syncUserModalShow = true
          this.userOAuth = responsed.data
        } else {
          if (!responsed.data.isVerify) {
            // this.$router.push({ path: this.$i18n.path('otp') })
            this.onRequestOTP(this.login.email, 'LoginVerify')
          } else {
            await this.setCartId(responsed.data.openId)
            await this.setAuthToken(responsed.data)
            // await this.setAuthToken(responsed.data).then(() =>
            //   this.$nextTick(() =>
            //     this.$router.push({ path: this.$i18n.path('approver/b2b') })
            //   )
            // )
            let openId =
              responsed.data.openId == null ||
              responsed.data.openId == undefined
                ? responsed.data.user.info.open_id
                : responsed.data.openId
            console.log('responsed.data.openId', openId)

            await this.getUserData(openId)
          }
        }
      } else if (responsed.status === 412) {
        if (responsed.data)
          this.showToast(this.$t('login.labelPreconditionFailed'))
      } else {
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
            en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
          },
        })
        this.modalWarning = true
      }
    },
    showToast(message) {
      const id = `error-toast-${this.toast++}`

      const Msg = this.$createElement('div', { class: ['mr-auto'] }, [message])
      const CloseToast = this.$createElement('b-icon-x', {
        class: ['ml-auto'],
        on: { click: () => this.$bvToast.hide(id) },
      })

      this.$bvToast.toast([Msg, CloseToast], {
        id: id,
        toaster: 'b-toaster-top-right error-toast',
        noCloseButton: true,
      })
    },
    async getUserData(openId) {
      await this.$profileProvider
        .getUserDataById(openId)
        .then((result) => {
          console.log('result', result)
          if (result.status === 200) {
            let userData = result.data
            // console.log('this.userProfile', this.userProfile)
            if (!userData.is_verify)
              // this.$router.push({ path: this.$i18n.path('otp') })
              this.onRequestOTP(userData.email, 'LoginVerify')
            else {
              let tempUserData = Object.assign({}, result.data)
              delete tempUserData['accounts']
              delete tempUserData['companies']
              // delete tempUserData['phones']
              delete tempUserData['create_info']
              delete tempUserData['update_info']
              this.setUserInfo({ payload: tempUserData })
              if (userData.accounts.length || userData.companies.length) {
                this.$bvToast.hide()
                if (
                  userData.accounts.length === 1 &&
                  userData.companies.length === 0
                ) {
                  // use account
                  this.setUserProfile({
                    payload: userData.accounts[0],
                    profileType: 'standard',
                  })
                  console.log('setUserProfile', {
                    payload: userData.accounts[0],
                    profileType: 'standard',
                  })
                  this.setUserProfile(
                    openId,
                    userData.accounts[0].id,
                    'standard'
                  )

                  // saveRouteHistory
                  // if (this.$utils.isValid(this.routeHistory)) {
                  //   window.location.href = this.routeHistory
                  // } else {
                  //   window.location.href =
                  //     this.$i18n.locale === 'th' ? '/' : '/en/'
                  // }
                  if (this.$utils.isValid(this.routeHistory)) {
                    this.$router.push({
                      path: this.routeHistory,
                    })
                  } else {
                    this.$router.push({ path: this.$i18n.path('') })
                    // this.$router.push({
                    //   path: `${
                    //     userData.default_language === 'th' ? '/' : '/en/'
                    //   }`,
                    // })
                  }
                  // braze
                  this.$setBrazeUser(
                    this.$store.getters['user/userInfo'],
                    this.$store.getters['user/profileType'],
                    this.$store.getters['user/userProfile']
                  )
                } else {
                  //select account || company
                  this.$router.push({
                    path: `${
                      userData.default_language === 'th'
                        ? '/account/select-company'
                        : '/en/account/select-company'
                    }`,
                  })
                }
              } else {
                this.signout()
                this.warningMessages = []
                this.warningMessages.push({
                  message: {
                    th: 'ไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าหน้าที่',
                    en: 'ไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าหน้าที่',
                  },
                })
                this.modalWarning = true
              }
            }
          } else {
            this.warningMessages = []
            this.warningMessages.push({
              message: {
                th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
              },
            })
            this.modalWarning = true
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async updateLastActive(openId, profileId, accountChannel) {
      let apiData = JSON.stringify({
        profile_id: profileId,
        account_channel: accountChannel,
      })
      await this.$profileProvider.updateUserLastActive(openId, apiData)
    },
    async syncUser() {
      console.log(this.userOAuth.User)
      let payload = {
        user_open_id: this.userOAuth.User.info.open_id,
        email: this.userOAuth.User.info.email,
        last_active: '',
        default_language: 'th',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
      }
      const responsed = await this.$profileProvider.syncUser(payload)
      if (responsed.status === 201) {
        console.log('responsed: ', responsed)
        await this.signInOAuth(this.userOAuth.User.access_token)
        // this.$router.push({ path: this.$i18n.path('otp') })
        this.onRequestOTP(this.userOAuth.User.info.email, 'LoginVerify')
      }
      this.syncUserModalShow = false
    },
    async signInOAuth(token) {
      let payload = { Token: token }
      const responsed = await this.$authenProvider.signInOauth(payload)
      if (responsed.status === 201) {
        console.log('responsed: ', responsed)
        this.setAuthToken(responsed.data)
        this.setCartId(responsed.data.openId)
        window.location.reload()
      }
    },
    onChangeLanguage(language) {
      // this.lang = language
      console.log('langauge', language)
      this.$router.push(
        `${
          language === 'th'
            ? this.$route.fullPath.replace(/^\/[^\/]+/, '')
            : this.$route.fullPath.includes('/en/')
            ? this.$route.fullPath
            : `/en${this.$route.fullPath}`
        }`
      )
    },
    redirectHomePage() {
      this.keyword = ''
      // this.$router.push({ path: this.$i18n.path('') })
    },
    async onRequestOTP(email, type) {
      console.log('onRequestOTP', email)
      let apiData = JSON.stringify({
        reference: {
          by_key: email,
          by_token: '',
          token_type: type,
        },
        channel: 'Email',
        is_skip_force_request: true,
      })
      console.log('apiData', apiData)
      const responsed = await this.$authenProvider.requestOTPByChannel(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        const dataResponse = responsed.data
        console.log('dataResponse', dataResponse)
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
    },
  },
}
