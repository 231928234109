
import InputText from '@/components/share/input-text.vue'
import SelectObject from '@/components/share/select-object.vue'
import { mapGetters, mapActions } from 'vuex'
import modalWarning from '@/components/modal/modalWarning.vue'
export default {
  layout: 'blank-nav',
  name: 'RegisterAddress',
  components: {
    'input-text': InputText,
    'select-object': SelectObject,
    'modal-warning': modalWarning,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      reloadAddress: true,
      district: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      sub_district: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      province: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      invoice_district: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      invoice_sub_district: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      invoice_province: [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ],
      isAddress: false,
      disabledInvAddress: false,
      disabledTaxId: false,
      dataForAddress: {
        email: '',
        title: '',
        name: '',
        middleName: '',
        lastName: '',
        titleEN: '',
        nameEN: '',
        middleNameEN: '',
        lastNameEN: '',
        phone: '',
        shippingAddress1: '',
        shippingAddress2: '',
        zipCode: '',
        district: '',
        subDistrict: '',
        province: '',
        remark: '',
        taxId: '',
        invAddress1: '',
        invAddress2: '',
        invZipCode: '',
        invDistrict: '',
        invSubDistrict: '',
        invProvince: '',
        is_deliveryfee: '',
      },
      zoneList: [],
      modalWarning: false,
      warningMessages: [],
      tempProvinceGroup: [],
      tempInvProvinceGroup: [],
      issubmit: true,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType']),
    requiredTaxId() {
      if (typeof atob === 'function') {
        let decode = atob(this.$route.query.accountType)
        return decode === 'Corporate' ? true : false
      }
      return false
    },
    shippingAddress3() {
      if (
        Object.keys(this.dataForAddress.province).length &&
        this.$utils.isValid(this.dataForAddress.zipCode)
      ) {
        return this.dataForAddress.province.th.includes('กรุงเทพ')
          ? `แขวง${this.dataForAddress.subDistrict.th} เขต${this.dataForAddress.district.th}`
          : `ตำบล${this.dataForAddress.subDistrict.th} อำเภอ${this.dataForAddress.district.th}`
      } else return ''
    },
    shippingAddress4() {
      if (
        Object.keys(this.dataForAddress.province).length &&
        this.$utils.isValid(this.dataForAddress.zipCode)
      ) {
        return this.dataForAddress.province.th.includes('กรุงเทพ')
          ? `${this.dataForAddress.province.th} ${this.dataForAddress.zipCode}`
          : `จังหวัด${this.dataForAddress.province.th} ${this.dataForAddress.zipCode}`
      } else return ''
    },
    invoiceAddress3() {
      if (
        Object.keys(this.dataForAddress.invProvince).length &&
        this.$utils.isValid(this.dataForAddress.invZipCode)
      ) {
        return this.dataForAddress.province.th.includes('กรุงเทพ')
          ? `แขวง${this.dataForAddress.invSubDistrict.th} เขต${this.dataForAddress.invDistrict.th}`
          : `ตำบล${this.dataForAddress.invSubDistrict.th} อำเภอ${this.dataForAddress.invDistrict.th}`
      } else return ''
    },
    invoiceAddress4() {
      if (
        Object.keys(this.dataForAddress.invProvince).length &&
        this.$utils.isValid(this.dataForAddress.invZipCode)
      ) {
        return this.dataForAddress.province.th.includes('กรุงเทพ')
          ? `${this.dataForAddress.invProvince.th} ${this.dataForAddress.invZipCode}`
          : `จังหวัด${this.dataForAddress.invProvince.th} ${this.dataForAddress.invZipCode}`
      } else return ''
    },
  },
  watch: {
    isAddress: function (val) {
      this.disabledInvAddress = val
      if (val === true) {
        this.$nextTick(() => {
          this.dataForAddress.invAddress1 =
            this.dataForAddress.name + ' ' + this.dataForAddress.lastName
          this.dataForAddress.invAddress2 =
            this.dataForAddress.shippingAddress1 +
            ' ' +
            this.dataForAddress.shippingAddress2
          this.dataForAddress.invZipCode = this.dataForAddress.zipCode
          this.invoice_province = this.province
          this.dataForAddress.invProvince = this.dataForAddress.province
          this.invoice_district = this.district
          this.dataForAddress.invDistrict = this.dataForAddress.district
          this.invoice_sub_district = this.sub_district
          this.dataForAddress.invSubDistrict = this.dataForAddress.subDistrict
          // this.searchZone(this.dataForAddress.invZipCode, 'invoice')

          // console.log(this.dataForAddress.invDistrict)
          console.log(this.invoice_sub_district)
          console.log(this.dataForAddress.invSubDistrict)
        })
      }
    },
    'dataForAddress.district': function (val) {
      console.log('val', val)
      let filterZone = this.zoneList.filter((q) => q.district.th === val.th)
      this.sub_district = [
        {
          text: this.$t('account-new-register-address.defaultText'),
          value: '',
        },
      ]
      this.dataForAddress.subDistrict = ''
      if (filterZone != undefined && filterZone.length) {
        filterZone.forEach((item) => {
          this.sub_district.push({
            text: item.sub_district.th,
            value: item.sub_district,
          })
          this.dataForAddress.is_deliveryfee = item?.is_deliveryfee
        })
        this.dataForAddress.subDistrict = this.sub_district[1].value
      }
    },
    'dataForAddress.invDistrict': function (val) {
      let filterZone = this.zoneList.filter((q) => q.district.th === val.th)
      if (!this.isAddress) {
        this.invoice_sub_district = [
          {
            text: this.$t('account-new-register-address.defaultText'),
            value: '',
          },
        ]
        this.dataForAddress.invSubDistrict = ''
      }
      if (filterZone != undefined && filterZone.length) {
        if (!this.isAddress) {
          filterZone.forEach((item) => {
            this.invoice_sub_district.push({
              text: item.sub_district.th,
              value: item.sub_district,
            })
          })
          this.dataForAddress.invSubDistrict =
            this.invoice_sub_district[1].value
        }
      }
    },
    'dataForAddress.province': {
      handler: function (val) {
        if (val.en) {
          this.tempProvinceGroup[
            this.tempProvinceGroup.findIndex((e) => e.province.en === val.en)
          ].items.forEach((item) => {
            if (
              this.district.find((q) => q.value.en === item.district.en) ===
              undefined
            ) {
              this.district.push({
                text: item.district.th,
                value: item.district,
              })

              this.dataForAddress.district = this.district[1].value
            }
          })
        }
      },
      deep: true,
    },
    'dataForAddress.invProvince': {
      handler: function (val) {
        if (val.en && !this.isAddress) {
          this.tempInvProvinceGroup[
            this.tempInvProvinceGroup.findIndex((e) => e.province.en === val.en)
          ].items.forEach((item) => {
            if (
              this.invoice_district.find(
                (q) => q.value.en === item.district.en
              ) === undefined
            ) {
              this.invoice_district.push({
                text: item.district.th,
                value: item.district,
              })

              this.dataForAddress.invDistrict = this.invoice_district[1].value
            }
          })
        } else {
          console.log()
        }
      },
      deep: true,
    },
  },
  mounted() {
    // if (
    //   Object.keys(this.$route.query).length &&
    //   this.$utils.isValid(this.$route.query.taxId)
    // ) {
    //   this.dataForAddress.taxId = atob(this.$route.query.taxId)
    //   this.disabledTaxId = true
    // }
    if (
      Object.keys(this.$route.query).length &&
      localStorage.getItem('tax_id')
    ) {
      this.dataForAddress.taxId = atob(localStorage.getItem('tax_id'))
      this.disabledTaxId = true
    }
    this.checkAccountActivate()
  },
  methods: {
    ...mapActions('user', ['setUserInfo', 'setUserProfile']),
    async searchZone(event, source) {
      if (event.length >= 5) {
        const params = new URLSearchParams()
        params.append('keyword', event)
        await this.$logisticProvider
          .getZone(params)
          .then((result) => {
            console.log(result)
            this.zoneList = result.data
            if (result.data.length) {
              if (source === 'shipping') {
                this.province = [
                  {
                    text: this.$t('account-new-register-address.defaultText'),
                    value: '',
                  },
                ]
                this.district = [
                  {
                    text: this.$t('account-new-register-address.defaultText'),
                    value: '',
                  },
                ]
                // groups
                let value = result.data
                let groups = []
                value = value.reduce((h, value) => {
                  return Object.assign(h, {
                    [value['province']['en']]: (
                      h[value['province']['en']] || []
                    ).concat(value),
                  })
                }, {})
                // console.log('value', value)
                Object.keys(value).forEach((element) => {
                  groups.push({
                    province: value[element][0].province,
                    items: value[element],
                  })
                })
                this.tempProvinceGroup = groups

                this.tempProvinceGroup.forEach((e) => {
                  this.province.push({
                    text: e.province.th,
                    value: e.province,
                  })
                })

                this.dataForAddress.province = groups[0].province
              } else if (source === 'invoice') {
                this.invoice_province = [
                  {
                    text: this.$t('account-new-register-address.defaultText'),
                    value: '',
                  },
                ]
                if (!this.isAddress)
                  this.invoice_district = [
                    {
                      text: this.$t('account-new-register-address.defaultText'),
                      value: '',
                    },
                  ]
                console.log('on search inv')
                let value = result.data
                let groups = []
                value = value.reduce((h, value) => {
                  return Object.assign(h, {
                    [value['province']['en']]: (
                      h[value['province']['en']] || []
                    ).concat(value),
                  })
                }, {})
                // console.log('value', value)
                Object.keys(value).forEach((element) => {
                  groups.push({
                    province: value[element][0].province,
                    items: value[element],
                  })
                })
                this.tempInvProvinceGroup = groups

                this.tempInvProvinceGroup.forEach((e) => {
                  this.invoice_province.push({
                    text: e.province.th,
                    value: e.province,
                  })
                })
                if (!this.isAddress)
                  this.dataForAddress.invProvince = groups[0].province
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            console.log('finally!!')
          })
      } else {
        this.zoneList = []
        if (source === 'shipping') {
          this.dataForAddress.subDistrict = ''
          this.dataForAddress.district = ''
          this.dataForAddress.province = ''
          this.sub_district = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
          this.district = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
          this.province = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
        } else if (source === 'invoice') {
          this.dataForAddress.invSubDistrict = ''
          this.dataForAddress.invDistrict = ''
          this.dataForAddress.invProvince = ''
          this.invoice_sub_district = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
          this.invoice_district = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
          this.invoice_province = [
            {
              text: this.$t('account-new-register-address.defaultText'),
              value: '',
            },
          ]
        }
      }
    },
    onSubmit() {
      this.$refs.addressForm.validate().then((result) => {
        if (result) {
          if (this.issubmit) {
            this.updateInformation()
          }
        }
      })
    },
    async updateInformation() {
      let phoneType =
        this.dataForAddress.phone.length === 9 ? 'Phone' : 'Mobile'
      let apiData = JSON.stringify({
        id: this.openId,
        onlineAccountId: this.userProfile.id,
        accountType: atob(this.$route.query.accountType),
        taxId: this.dataForAddress.taxId,
        invoice: {
          // id: '1',
          offline_id: 0,
          status: 'Active',
          address_1: this.dataForAddress.invAddress1,
          address_2: this.dataForAddress.invAddress2,
          address_3: this.invoiceAddress3,
          address_4: this.invoiceAddress4,
          subdistrict: this.dataForAddress.invSubDistrict,
          district: this.dataForAddress.invDistrict,
          province: this.dataForAddress.invProvince,
          zip_code: this.dataForAddress.invZipCode,
          sub_area: '',
          branch_id: atob(this.$route.query.branchId),
          is_default: true,
          tax_id: this.dataForAddress.taxId,
        },
        shipping: {
          // id: '1',
          offline_id: 0,
          contact: `${this.dataForAddress.name} ${this.dataForAddress.lastName}`,
          status: 'Active',
          address_1: this.dataForAddress.shippingAddress1,
          address_2: this.dataForAddress.shippingAddress2,
          address_3: this.shippingAddress3,
          address_4: this.shippingAddress4,
          district: this.dataForAddress.district,
          subdistrict: this.dataForAddress.subDistrict,
          province: this.dataForAddress.province,
          zipcode: this.dataForAddress.zipCode,
          sub_area: '',
          is_default: true,
          ship_type: 'DELIVERY',
          remark: this.dataForAddress.remark,
          is_deliveryfee: this.dataForAddress.is_deliveryfee,
          phones: [
            {
              type: phoneType,
              value: this.dataForAddress.phone,
              extension: '',
            },
          ],
        },
      })
      console.log('object :>> ', apiData)
      await this.$profileProvider
        .createBackendCustomer(apiData)
        .then(async (result) => {
          console.log('createBackendCustomer', result)
          if (result.status === 201) {
            this.issubmit = false
            localStorage.removeItem('tax_id')
            if (Object.keys(this.$route.query).length) {
              if (this.$utils.isValid(this.$route.query.callback)) {
                if (this.$route.query.callback === 'checkout/shipping') {
                  this.createOrderTemporary('checkout/shipping')
                } else {
                  await this.getUserData()
                }
              } else this.$router.push({ path: this.$i18n.path('') })
            } else this.$router.push({ path: this.$i18n.path('') })
          } else if (result.status === 500) {
            if (result.data.message === 'Create Customer Failed') {
              //เฉพาะ case ที่สร้าง account,shipping,invoice update flag is_activate ไม่ผ่านถึงจะขึ้น popup
              this.warningMessages = []
              this.warningMessages.push({
                message: {
                  th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                  en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                },
              })
              this.modalWarning = true
            } else {
              // กรณีที่สร้าง account,shipping,invoice ได้แต่หลังจากนั้นไป error ตอน สร้างลูกค้าหลังบ้านหรือ
              // นำข้อมูลหลังบ้านกลับขึ้นมาอัพเดตให้พา user กลับไปหน้า profile || cart ตาม redirect link ที่แนบมา
              localStorage.removeItem('tax_id')
              if (Object.keys(this.$route.query).length) {
                if (this.$utils.isValid(this.$route.query.callback)) {
                  if (this.$route.query.callback === 'checkout/shipping')
                    this.createOrderTemporary('checkout/shipping')
                  else {
                    this.$router.push({
                      path: this.$i18n.path(this.$route.query.callback),
                    })
                  }
                } else this.$router.push({ path: this.$i18n.path('') })
              } else this.$router.push({ path: this.$i18n.path('') })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId, false)
        .then((result) => {
          this.userData = result.data

          if (!this.userData.is_verify) {
            // this.$router.push({ path: this.$i18n.path('otp') })
            this.onRequestOTP(this.userData.email, 'LoginVerify')
          } else {
            let tempUserData = Object.assign({}, result.data)
            delete tempUserData['accounts']
            delete tempUserData['companies']
            // delete tempUserData['phones']
            delete tempUserData['create_info']
            delete tempUserData['update_info']
            this.setUserInfo({ payload: tempUserData })
            if (
              this.userProfile !== undefined &&
              Object.keys(this.userProfile).length
            ) {
              // case has seleced profile
              // console.log('this.profileType', this.profileType)
              if (this.profileType === 'standard') {
                let account = result.data.accounts.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('account', account)
                this.setUserProfile({
                  payload: account,
                  profileType: 'standard',
                })
              } else if (this.profileType === 'eprocurement') {
                let company = result.data.companies.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('company', company)
                this.setUserProfile({
                  payload: company,
                  profileType: 'eprocurement',
                })
                if (
                  this.$utils.isPasswordExpire(
                    this.userData.companies[0].password_expire
                  )
                )
                  // this.$router.push({
                  //   path: this.$i18n.path('changepassword'),
                  // })
                  this.onRequestOTP(this.userData.email, 'ChangePassword')
              }
              this.$nextTick(() => {
                this.$router.push({
                  path: this.$i18n.path(this.$route.query.callback),
                })
              })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async createOrderTemporary(redirectPath) {
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        reqesterline_id: '',
        companies_budget_control_id: '',
        voucher_no: this.$utils.isValid(this.$route.query.voucher_no)
          ? this.$route.query.voucher_no
          : '',
      })
      const result = await this.$shoppingCartProvider.createOrderTemporary(
        apiData
      )
      if (result.status === 201) {
        await this.getUserData()
        this.$router.push({
          path: redirectPath,
          name:
            this.$i18n.locale === 'th'
              ? 'checkout-shipping-tempId'
              : 'lang-checkout-shipping-tempId',
          params: { tempId: result.data.temp_id },
        })
      }
    },
    checkAccountActivate() {
      console.log('checkAccountActivate', this.userProfile)
      if (this.userProfile.is_activate) {
        this.$router.push({
          path: this.$i18n.path(this.$route.query.callback),
        })
      }
    },
    async onRequestOTP(email, type) {
      console.log('onRequestOTP', email)
      let apiData = JSON.stringify({
        reference: {
          by_key: email,
          by_token: '',
          token_type: type,
        },
        channel: 'Email',
        is_skip_force_request: true,
      })
      console.log('apiData', apiData)
      const responsed = await this.$authenProvider.requestOTPByChannel(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        const dataResponse = responsed.data
        console.log('dataResponse', dataResponse)
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
    },
  },
}
