
import jwtDecode from 'jwt-decode'
import { mapActions } from 'vuex'
import modalWarning from '@/components/modal/modalWarning'
const Cookie = process.client ? require('js-cookie') : undefined
import { mapGetters } from 'vuex'
export default {
  layout: 'blank-nav',
  name: 'VerifyOTP',
  components: {
    Otp: async () => await import('@/components/template/otp.vue'),
    notfound: async () => await import('@/components/share/notfound.vue'),
    'modal-warning': modalWarning,
  },
  data() {
    return {
      isVerifyOTP: false,
      isLoading: true,
      isNotFound: false,
      isNotFound403: false,
      token_type: '',
      jwt: {},
      objOtpVerify: {},
      warningMessages: [],
      modalWarning: false,
      objSetCookies: {},
      objResponse: {},
      verifyKey: '',
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
  },
  async mounted() {
    console.log('welcome OTP')
    this.verifyKey = this.$route.query.verify_key

    console.log('this.$route.query.verify_key', this.verifyKey)

    if (this.isValidVerifyKey(this.verifyKey)) {
      console.log('1')
      await this.onVerifyFromMail()
    } else {
      console.log('4')
      this.isNotFound = true
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('authen', ['setAuthToken']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', ['setUserProfile', 'setUserInfo']),

    isValidVerifyKey(key) {
      console.log('isValidVerifyKey')
      return key !== '' && key !== undefined && key !== null
    },

    async getOtpVerify(verify_key) {
      console.log('getOtpVerify')
      try {
        const response = await this.$profileProvider.getOtpVerify(verify_key)
        console.log('response: ', response)

        if (response.status === 200) {
          const permission = await this.onCheckPermission(
            response.data.user_open_id
          )
          console.log('checkPermission', permission)
          if (permission) {
            const expiryDate = new Date(Date.parse(response.data.expiry_date))
            if (expiryDate < Date.now()) {
              this.onExpireOTPVerify()
            } else if (response.data.is_otp_verify) {
              this.onVerifysuccess()
            } else {
              this.objOtpVerify = response.data
              console.log('response: this.objOtpVerify', this.objOtpVerify)
              this.isLoading = false
              this.isVerifyOTP = true
            }
          }
        } else {
          this.onExpireOTPVerify()
        }
      } catch (error) {
        console.error('Error verifying OTP:', error)
        // Handle error accordingly
        this.onExpireOTPVerify()
      }
    },

    async onVerifyFromMail() {
      console.log('onVerifyFromMail')
      await this.DecodeJWT()

      if (this.jwt.token_type === 'Invite') {
        console.log('1 onVerifyFromMail')
        await this.onInviteUser()
      } else {
        console.log('2 onVerifyFromMail')
        this.getOtpVerify(this.$route.query.verify_key)
      }
    },

    async DecodeJWT() {
      try {
        const decoded = jwtDecode(this.$route.query.verify_key)
        this.jwt = decoded
        console.log('decoded', this.jwt)
      } catch (error) {
        this.onExpireOTPVerify()
      }
    },

    onExpireOTPVerify() {
      console.log('Link หมดอายุ กรุณา login หรือยืนยันตัวตนใหม่')

      this.isLoading = false
      this.isVerifyOTP = false

      this.warningMessages = [
        {
          message: {
            th: 'Link หมดอายุ กรุณา login หรือยืนยันตัวตนใหม่',
            en: 'Link หมดอายุ กรุณา login หรือยืนยันตัวตนใหม่',
          },
        },
      ]
      this.modalWarning = true
    },
    onVerifysuccess() {
      console.log('onVerifysuccess')

      this.isLoading = false
      this.isVerifyOTP = false

      this.warningMessages = [
        {
          message: {
            th: 'คุณได้ทำการยืนยันตัวตนเรียบร้อยแล้ว',
            en: 'คุณได้ทำการยืนยันตัวตนเรียบร้อยแล้ว',
          },
        },
      ]
      this.modalWarning = true
    },
    onAction(responseAction) {
      console.log('onAction', responseAction)
      this.objResponse = responseAction
      console.log('onAction this.objResponse', responseAction)
      const token_type = this.objOtpVerify.token_type
      console.log('token_type', token_type)
      if (token_type === 'Register' || token_type === 'LoginVerify') {
        this.signin()
      } else if (token_type === 'ForgotPassword') {
        this.$router.push({
          path: this.$i18n.path('newpassword'),
          query: { id: responseAction.otp_prove_token },
        })
      } else if (token_type === 'ChangePassword') {
        this.$router.push({
          path: this.$i18n.path('changePassword'),
          query: { id: responseAction.otp_prove_token },
        })
        console.log('this.$route.query : ', this.$router.query)
      } else if (token_type === 'Invite') {
        this.$router.push({
          path: this.$i18n.path('newpassword'),
          query: { id: responseAction.otp_prove_token },
        })
      }
    },
    onlogin() {
      this.modalWarning = false
      this.$router.push({ path: this.$i18n.path('login') })
    },
    async signin() {
      console.log('signin', this.objResponse)
      const otp_prove_token = this.objResponse.otp_prove_token
      const otp_id = this.objResponse.otp_id
      const ref_code = this.objResponse.ref_code
      let apiData = JSON.stringify({
        otp_id: otp_id,
        ref_code: ref_code,
      })
      console.log('createUsersVerify', apiData)
      const responsed = await this.$profileProvider.createUserVerify(
        otp_prove_token,
        apiData
      )
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        // await this.clearCookies()
        await this.setCartId(responsed.data.openId)
        await this.setAuthToken(responsed.data)
        await this.getUserData(responsed.data.openId)
      }
    },
    async getUserData(openId) {
      var vm = this
      await this.$profileProvider
        .getUserDataById(openId)
        .then((result) => {
          console.log('getUserDataById', result)
          let userData = result.data

          if (userData.accounts.length || userData.companies.length) {
            if (
              userData.accounts.length === 1 &&
              userData.companies.length === 0
            ) {
              // use account
              vm.setUserProfile({
                payload: userData.accounts[0],
                profileType: 'standard',
              })
              this.updateLastActive(
                this.openId,
                userData.accounts[0].id,
                'standard'
              )
              this.$router.push({
                path: this.$i18n.path(''),
              })
            } else if (
              userData.accounts.length === 0 &&
              userData.companies.length === 1
            ) {
              //use company
              vm.setUserProfile({
                payload: userData.companies[0],
                profileType: 'eprocurement',
              })
              if (
                this.$utils.isPasswordExpire(
                  userData.companies[0].password_expire
                )
              ) {
                // this.$router.push({
                //   path: this.$i18n.path('changepassword'),
                // })
                this.onRequestOTP(userData.email, 'ChangePassword')
              } else {
                let tempUserData = Object.assign({}, result.data)
                delete tempUserData['accounts']
                delete tempUserData['companies']
                delete tempUserData['create_info']
                delete tempUserData['update_info']
                this.setUserInfo({ payload: tempUserData })
                // braze
                this.$setBrazeUser(
                  this.$store.getters['user/userInfo'],
                  this.$store.getters['user/profileType'],
                  this.$store.getters['user/userProfile']
                )
                // gtm register
                this.$gtm.push({
                  event: 'register',
                  ...this.$store.getters['user/userInfoTrack'],
                })
                // this.$router.push({
                //   path: this.$i18n.path(''),
                // })
                window.location.href = this.$i18n.path('')
              }
            } else {
              //select account || company
              this.$router.push({
                path: this.$i18n.path('account/select-company'),
              })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async updateLastActive(openId, profileId, accountChannel) {
      let apiData = JSON.stringify({
        profile_id: profileId,
        account_channel: accountChannel,
      })
      await this.$profileProvider.updateUserLastActive(openId, apiData)
    },
    async onInviteUser() {
      console.log('onInviteUser')
      const permission = await this.onCheckPermission(this.jwt.openid)
      console.log('checkPermission', permission)
      if (permission) {
        let apiData = JSON.stringify({
          verify_key: this.$route.query.verify_key,
        })
        const responsed = await this.$profileProvider.checkInvite(apiData)
        console.log('responsed: ', responsed)
        if (responsed.status === 201) {
          const dataResponse = responsed.data
          console.log('dataResponse', dataResponse)

          if (this.isValidVerifyKey(dataResponse.verify_key)) {
            this.getOtpVerify(dataResponse.verify_key)
          }
        } else if (responsed.status === 400) {
          if (responsed.data.code === 'C000') {
            this.isLoading = false
            this.isVerifyOTP = false

            this.warningMessages = []
            this.warningMessages.push({
              message: {
                th: responsed.data.message.th,
                en: responsed.data.message.en,
              },
            })
            this.modalWarning = true
            return
          }
        }
      }
    },

    async onCheckPermission(userOpenId) {
      console.log('onCheckPermission')
      if (
        this.openId !== undefined &&
        this.openId !== '' &&
        userOpenId !== undefined &&
        this.openId !== userOpenId
      ) {
        console.log('isNotFound', this.openId + ' ' + userOpenId)
        this.isNotFound403 = true
        this.isLoading = false
        this.isVerifyOTP = false
        return false
      }
      return true
    },
    async onRequestOTP(email, type) {
      console.log('onRequestOTP', email)
      let apiData = JSON.stringify({
        reference: {
          by_key: email,
          by_token: '',
          token_type: type,
        },
        channel: 'Email',
        is_skip_force_request: true,
      })
      console.log('apiData', apiData)
      const responsed = await this.$authenProvider.requestOTPByChannel(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        const dataResponse = responsed.data
        console.log('dataResponse', dataResponse)
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
    },
  },
}
