
import { mapGetters, mapActions } from 'vuex'
import { debounce } from '@/plugins/utils'
import {
  HubConnectionBuilder,
  HttpTransportType,
  // LogLevel,
} from '@aspnet/signalr'
import {
  NotificationEnum,
  NotificationOFMEnum,
  NotificationSenderType,
} from '@/enum/notification.enum.js'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'MainNavbar',
  components: {
    // AisInstantSearch,
    // AisSearchBox,
    // AisAutocomplete,
    // AisHighlight,
  },
  head() {
    return {
      link: [
        {
          rel: 'preload',
          as: 'style',
          href: 'https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css',
        },
      ],
    }
  },
  data() {
    return {
      cartItems: [],
      selectNav: '',
      // searchClient,
      isLogin: true,
      query: '',
      searchProducts: '',
      showSearchResult: false,
      setTimeoutResult: '',
      sku: {},
      skuName: '',
      skuSEOName: '',
      // searchResult: {
      //   type: [],
      //   items: [],
      // },
      lang: '',
      isAdmin: false,
      loadingCartItemFlag: false,
      keyword: '',
      page: 0,
      perPage: 5,
      sort: 'price-ascending',
      itemProducts: [],
      itemSkus: [],
      itemFacets: [],
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      loadingSearchResult: false,
      notificationCount: 0,
      loadingNotificationFlag: false,
      isNotification: false,
      objNotification: [],
      queryIdEventAlgolia: '',
    }
  },
  created() {
    //this.$notification.requestPermission().then(console.log)
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    ...mapGetters('cart', ['cartCount', 'skuCount']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
      'changeSitePermission',
    ]),
    userName() {
      if (this.userInfo !== undefined && Object.keys(this.userInfo).length) {
        return `${this.userInfo.first_name[this.$i18n.locale]} ${
          this.userInfo.last_name[this.$i18n.locale]
        }`
      } else return ''
    },
    isEprocurementUser() {
      return this.$utils.isValid(this.profileType) &&
        this.profileType === 'eprocurement'
        ? true
        : false
    },
    isAdminEprocurementUser() {
      if (
        this.$utils.isValid(this.profileType) &&
        this.profileType === 'eprocurement'
      ) {
        if (
          Object.keys(this.userProfile).length &&
          this.userProfile.roles.length
        ) {
          let roleAdminList = this.userProfile.roles.filter(
            (q) => q.role_id === 1 || q.role_id === 2
          )
          return roleAdminList.length ? true : false
        } else return false
      } else return false
    },
    canChangeSite() {
      return this.changeSitePermission !== null &&
        this.changeSitePermission !== undefined
        ? this.changeSitePermission
        : false
    },
    facetResults() {
      return this.$utils.anyArrayObjectValid(this.itemFacets)
        ? this.itemFacets
        : []
    },
    skuResults() {
      return this.$utils.anyArrayObjectValid(this.itemSkus) ? this.itemSkus : []
    },
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
    isUsePrinting() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
    isUsePrintingMenu() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        // this.userProfile.roles.filter((a) => a.role_id === 3 || a.role_id === 5).length > 0
        this.userProfile.roles.filter((a) => a.role_id === 3).length > 0 &&
        this.userProfile.roles.filter((a) => a.role_id === 5).length > 0
      )
    },
    isUsePrintingMenuReport() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing &&
        this.userProfile.roles !== undefined &&
        this.userProfile.roles.filter((a) => a.role_id === 1 || a.role_id === 2)
          .length > 0
      )
    },
    isOrderPrinting() {
      return (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_product_printing
      )
    },
    // displayThumbnailShowcase() {
    //   console.log('displayThumbnailShowcase')
    //   if (
    //     this.$utils.isObjectValid(this.selectSku) &&
    //     this.$utils.anyArrayObjectValid(this.selectSku.images)
    //   ) {
    //     return this.selectSku.images.some((q) => q.size === 'thumbnail')
    //       ? this.selectSku.images.find((q) => q.size === 'thumbnail').url
    //       : this.imageTemp
    //   } else {
    //     return this.imageTemp
    //   }
    // },
    isCheckUserId() {
      return this.openId
    },
  },
  watch: {
    navMenu: {
      handler: function (val) {
        console.log(val)
      },
      deep: true,
    },
    keyword: debounce(function (val) {
      this.fetchResults()
    }, 250),
    '$i18n.locale': function (val) {
      this.lang = val
    },
  },
  beforeDestroy() {
    this.$nuxt.$off('myNotificationUpdate')
  },
  mounted() {
    this.lang = this.$i18n.locale
    if (this.isAuthenticated && !this.isGuest) {
      this.installSignalR()
      this.getNotification()
      this.getTotalMessages()
    }

    this.$nuxt.$on('myNotificationUpdate', () => {
      console.log('Noti myNotificationUpdate')
      this.getNotification()
    })
    console.log('userProfile', this.userProfile)

    const specifiedElement = document.getElementById('navbar-search')
    const _this = this
    document.addEventListener('click', function (event) {
      const isClickInside = specifiedElement.contains(event.target)
      if (!isClickInside) {
        _this.handleFocusOut()
      }
    })
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile', 'removeOTPVerify']),
    ...mapActions('index', ['saveRouteHistory']),

    async getNotification() {
      this.loadingNotificationFlag = true
      this.objNotification = new Array()
      const params = new URLSearchParams()
      params.append('page', '1')
      params.append('pageSize', '10')
      params.append('workspace_id', this.userProfile.id)
      params.append('notification_channel', 'OrderNotification')
      let result = await this.$productNotifyMeProvider.getNotification(params)
      console.log('Noti nav getNotificationApi : ', result)
      if (result.status === 200) {
        if (result.data != undefined && result.data != '') {
          result.data.items.forEach((element, index) => {
            let payloadContent = JSON.parse(element.web_notification.content)
            let senderName =
              this.lang == 'en'
                ? element.sender.sender_name.en === ''
                  ? element.sender.sender_name.th
                  : element.sender.sender_name.en
                : element.sender.sender_name.th
            let content = {
              id: element._id,
              title:
                this.lang == 'en'
                  ? payloadContent.title_en
                  : payloadContent.title_th,
              date: '',
              body:
                this.lang == 'en'
                  ? payloadContent.content_en
                  : payloadContent.content_th,
              active: element.status == NotificationEnum.UNREAD ? true : false,
              timestamp: element.timestamp,
              status: element.status,
              sender_name: senderName,
              firstCha: this.getFirstChalater(
                element.sender.sender_type,
                senderName,
                element.sender.photo_url
              ),
              redirectPath:
                element.web_notification.interact_methods.click.action_value,
              isOFMsender:
                element.sender.sender_type == NotificationSenderType.application
                  ? true
                  : false,
              remark: payloadContent.remark,
            }

            this.objNotification.push(content)
          })
        }
      }
      this.onNotification()
      this.getTotalMessages()
      this.loadingNotificationFlag = false
      console.log('Noti nav objNotification', this.objNotification)
    },
    installSignalR() {
      // console.log('signalR')
      const Cookie = process.client ? require('js-cookie') : undefined
      let accessToken = Cookie.get('accessToken')
      const connection = new HubConnectionBuilder()
        .withUrl(
          `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}NotificationUserCenter?access_token=${accessToken}`,
          {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          }
        )
        .build()
      connection.on('onOrderNotification', (articleHeading, articleContent) => {
        let content = JSON.parse(articleContent)
        console.log('connection articleHeading: ', articleHeading)
        console.log('connection articleContent: ', content)

        this.NotiToUser(content)
      })

      // if connection closed, reopen it
      let startedPromise = null
      function start() {
        startedPromise = connection
          .start()
          .catch((err) => {
            return new Promise((resolve, reject) =>
              setTimeout(() => start().then(resolve).catch(reject), 5000)
            )
          })
          .then(function () {
            connection.invoke('GetConnectionId').then(function (connectionId) {
              console.log('connectionId', connectionId)
            })
          })

        console.log('start')
        return startedPromise
      }

      connection.onclose(() => start())
      start()
    },
    NotiToUser(data) {
      let dataContent = JSON.parse(data.web_notification.content)
      let senderName =
        this.lang == 'en'
          ? data.sender.sender_name.en === ''
            ? data.sender.sender_name.th
            : data.sender.sender_name.en
          : data.sender.sender_name.th
      let content = {
        id: data.id,
        title:
          this.lang == 'en'
            ? data.web_notification.title_en
            : data.web_notification.title,
        date: '',
        body:
          this.lang == 'en' ? dataContent.content_en : dataContent.content_th,
        active: data.status == NotificationEnum.UNREAD ? true : false,
        timestamp: data.timestamp,
        sender_name: senderName,
        firstCha: this.getFirstChalater(
          data.sender.sender_type,
          senderName,
          data.sender.photo_url
        ),
        redirectPath: data.web_notification.interact_methods.click.action_value,
        isOFMsender:
          data.sender.sender_type == NotificationSenderType.application
            ? true
            : false,
        remark: dataContent.remark,
      }
      this.objNotification.splice(0, 0, content)
      console.log('Noti nav objNotification', this.objNotification)
      this.onNotification()
      this.notificationCount = this.notificationCount + 1
    },
    async onNotification() {
      const vm = this
      vm.objNotification.forEach((element, index) => {
        vm.$set(
          vm.objNotification[index],
          'date',
          vm.timeSince(element.timestamp)
        )
      })
      this.isNotification = this.objNotification.length > 0 ? true : false
    },
    async readNoti(item) {
      console.log('Noti readNoti', item)
      this.$productNotifyMeProvider
        .updateStatusNotification(item.id, NotificationEnum.READ)
        .then(() => {
          this.getNotification()
        })
    },
    async getTotalMessages() {
      const params = new URLSearchParams()
      params.append('status', 'Unread')
      params.append('notification_channel', 'OrderNotification')
      params.append('workspace_id', this.userProfile.id)
      let unreadItems =
        await this.$productNotifyMeProvider.getNotificationCount(params)
      if (unreadItems.status === 200) {
        this.notificationCount = unreadItems.data.total
      }
    },
    getFirstChalater(sender_type, name, logo_uri) {
      if (sender_type == NotificationSenderType.application) {
        if (logo_uri == '' || logo_uri === null) {
          logo_uri = NotificationOFMEnum.LINK_CDN
        }
        return logo_uri
      } else {
        if (name !== null && name !== undefined) {
          name = name.trim()
          let first_last_name = name.split(' ')
          let firstname = this.regularVowels(first_last_name[0]).charAt(0)
          let lastname = ''
          if (first_last_name.length >= 2) {
            lastname = this.regularVowels(first_last_name[1]).charAt(0)
          }
          return firstname + lastname
        }
      }
      return ''
    },
    regularVowels(name) {
      let vowels = ['เ', 'แ', 'โ', 'ใ', 'ไ']
      for (const element of vowels) {
        name = name.replace(element, '')
      }
      return name
    },
    timeSince(dateObject) {
      let date = new Date(dateObject)
      let dataAndTime =
        date.getDate() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        date.getFullYear() +
        ' ' +
        date.getHours() +
        ':' +
        ('0' + (date.getMinutes() + 1)).slice(-2)
      let seconds = Math.floor((new Date() - date) / 1000)
      let interval = seconds / 31536000
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 86400
      if (interval > 1) {
        return dataAndTime
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$t(`notification.hours_ago`)
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' ' + this.$t(`notification.minutes_ago`)
      }
      return (
        (Math.floor(seconds) <= 0 ? 1 : Math.floor(seconds)) +
        ' ' +
        this.$t(`notification.seconds_ago`)
      )
    },
    onShowResult() {
      if (this.keyword) {
        if (this.itemFacets.length) {
          this.showSearchResult = true
          this.$nuxt.$emit('onActiveOverlay', true)
        }
      }
    },
    async fetchResults() {
      if (this.keyword.length < 3) {
        this.loadingSearchResult = false
        this.showSearchResult = false
        this.$nuxt.$emit('onActiveOverlay', false)
        this.itemFacets = []
        // return false
      } else {
        // this.showSearchResult = false
        // this.$nuxt.$emit('onActiveOverlay', false)
        this.loadingSearchResult = true
        this.sort = 'products'
        let params = await this.createParams()
        const response = await this.$productProvider.productsSearchEngine(
          params,
          JSON.stringify({
            brands: [],
            categoriesLevel1s: [],
            attributes: [],
          })
        )
        this.loadingSearchResult = false
        if (response.status === 201) {
          this.itemProducts = []
          this.itemSkus = []
          console.log('response.data', response.data)
          if (
            this.$utils.isObjectValid(response.data) &&
            this.$utils.isObjectValid(response.data.items) &&
            response.data.items.totalRecords > 0
          ) {
            this.itemProducts = response.data.items.items
            this.randomSkus()
            this.showSearchResult = true
            this.$nuxt.$emit('onActiveOverlay', true)
          }

          let facets = response.data.facets
          // console.log('facets', facets)
          if (this.$utils.anyArrayObjectValid(facets)) {
            this.attribute = []
            /* categories_level1 */
            let categories_level1 = facets.find(
              (q) => q.display_name === 'categories_level1.display_name'
            )
            if (categories_level1 !== null) {
              let categories_level1_list = []
              for (const element of categories_level1.values) {
                console.log('categories_level1', element)
                categories_level1_list.push({
                  text: element.key[this.lang],
                  value: {
                    by: 'categories_level1',
                    value: {
                      th: element.key['th'],
                      en: element.key['en'],
                    },
                    seoName: {
                      th: element.seo_name['th'],
                      en: element.seo_name['en'],
                    },
                  },
                  // value: { by: 'categories_level1', value: categories_level1.values[i].value },
                })
              }
              this.itemFacets = categories_level1_list
              // console.log('this.itemFacets', this.itemFacets)
            }

            // /* brand */
            // let brand = facets.find(
            //   (q) => q.display_name === 'brand.display_name'
            // )
            // if (brand != null) {
            //   // console.log('brand', brand)
            //   let brandList = []
            //   for (let i = 0; i < brand.values.length; i++) {
            //     brandList.push({
            //       text: brand.values[i].key[this.lang],
            //       value: {
            //         by: 'brand',
            //         value: `${brand.values[i].key['th']}__${brand.values[i].key['en']}`,
            //       },
            //       // value: { by: 'brand', value: brand.values[i].value },
            //     })
            //   }
            //   this.attribute.push({
            //     title: this.$t('search_keyword.labelTitleBrandAttribute'),
            //     list: brandList,
            //   })
            // }
            // /* price */
            // let price = facets.find(
            //   (q) => q.display_name === 'price_default.include_vat'
            // )
            // if (price != null) {
            //   // console.log(price)
            //   this.priceDefault = price.values[0].value
            // }
            // // for (let i = 0; i < facets.length; i++) {
            // //   facets[i].forEach((element) => {
            // //     categories_level1List.push({
            // //       text: element.display_name[this.lang],
            // //       value: { by: 'categories_level1', value: element.id },
            // //     })
            // //   })
            // // }
          }

          if (this.$utils.isValid(response.data.query_id)) {
            this.queryIdEventAlgolia = response.data.query_id
            //   this.$eventInsightsAlgolia.test(
            //     TypeEventInsightsAlgoliaEnum.CLICKAFTER,
            //     response.data.query_id,
            //     [this.itemProducts[0].skus[0].id]
            //   )
          }
        }
      }
    },
    async createParams() {
      let params = new URLSearchParams()
      params.append('keyword', this.keyword)
      params.append('userOpenId', this.openId)
      params.append('accountId', this.userProfile.id)
      /* profileType จะเป็น standard กับ eprocurement */
      params.append('accountChannel', this.profileType)
      /* category type exp. costcenter, company */
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      params.append('isFree', false)
      return params
    },
    isDiscountRate(item) {
      return (
        this.$utils.isObjectValid(item) &&
        this.$utils.anyArrayObjectValid(item.promotion_prices) &&
        this.$utils.isObjectValid(item.display_discount_rate) &&
        item.display_discount_rate > 0 &&
        item.is_promotion
      )
    },
    displayNameProductCart(item) {
      let productName = item.product_names.find(
        (q) => q.type.toLowerCase() === 'online'
      )
      if (this.$utils.isObjectValid(productName)) {
        return productName.display_name[this.$i18n.locale]
      }
      return ''
    },
    imagesThumbnailShowcase(productImageList) {
      if (this.$utils.anyArrayObjectValid(productImageList)) {
        return productImageList.some((q) => q.size === 'thumbnail')
          ? productImageList.find((q) => q.size === 'thumbnail').url
          : this.imageTemp
      } else {
        return this.imageTemp
      }
    },
    onClickViewOrderHistory(status) {
      this.$router.push({
        path: this.$i18n.path('order/history'),
        name:
          this.$i18n.locale === 'th' ? 'order-history' : 'lang-order-history',
        query: { tab: status },
      })
    },
    onRouteProductDetailByProduct(item, index) {
      console.log('item', item)
      this.showSearchResult = false
      this.$nuxt.$emit('onActiveOverlay', false)
      if (this.$utils.isValid(this.queryIdEventAlgolia)) {
        this.$eventInsightsAlgolia.sendEvent(
          TriggerInsightsAlgoliaEnum.SEARCHBARVIEWPDP,
          this.isGuest ? this.guestId : this.openId,
          this.queryIdEventAlgolia,
          [item.product_id],
          [++index]
        )
      }

      // this.$router.push({
      //   path: this.$i18n.path(`product/${item.seo_name}-p.${item.sku_code}`),
      //   query: { queryIdEventAlgolia: this.queryIdEventAlgolia },
      // })
    },
    onRouteProductDetailByShoppingCart(item) {
      console.log('item', item)
      let skuSEOName = item.meta.seo_name[this.lang]
      this.$router.push({
        path: this.$i18n.path(`product/${skuSEOName}-p.${item.sku_code}`),
        query: { queryIdEventAlgolia: this.queryIdEventAlgolia },
      })
    },
    onRouteCategoryLevelTwoByFacet(item) {
      console.log('item', item)
      this.showSearchResult = false
      this.$nuxt.$emit('onActiveOverlay', false)
      // this.$router.push({
      //   path: this.$i18n.path(`category/${item.value.seoName[this.lang]}`),
      // })
    },
    randomSkus() {
      this.itemProducts.forEach((item) => {
        let randomNumber = Math.floor(Math.random() * item.skus.length)
        let selectSku = item.skus[randomNumber]
        selectSku.display_name_online = selectSku.product_names.find(
          (q) => q.type.toLowerCase() === 'online'
        ).display_name
        selectSku.seo_name = selectSku.meta.seo_name[this.lang]
        // selectSku.small_image_url = selectSku.images.find(
        //   (q) => q.size === 'large'
        // ).url
        this.itemSkus.push(selectSku)
      })
    },
    async getCartItem() {
      this.loadingCartItemFlag = true
      // if (this.cartCount > 0) {
      const result = await this.$shoppingCartProvider
        .getCartItems(this.openId, this.userProfile.id, this.profileType)
        .finally(() => {
          setTimeout(() => {
            this.loadingCartItemFlag = false
          }, 100)
        })
      if (result.status === 200) {
        this.cartItems = result.data.items !== null ? result.data.items : []
        console.log('cartItems', this.cartItems)
      }
      // }
    },
    onClearNav() {
      this.selectNav = ''
    },
    searchResult() {
      this.showSearchResult = false
      this.$nuxt.$emit('onActiveOverlay', false)
      if (this.keyword !== '') {
        this.$router.push({ path: `/search/${this.keyword}` })

        // gtm GA4
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'search',
          ecommerce: {
            search_term: this.keyword,
          },
        })
      }
    },
    gotoCart() {
      this.$router.push({ path: this.$i18n.path('cart') })
    },
    gotoCatalog() {
      this.$router.push({ path: this.$i18n.path('account/favorite') })
    },
    toAccount() {
      this.$router.push({ path: this.$i18n.path('approver/b2b') })
    },
    toProfile() {
      this.$router.push({
        path: this.$i18n.path(`account/${this.userProfile.id}`),
      })
    },
    changeSite() {
      // this.removeUserProfile()
      this.$router.push({
        path: this.$i18n.path('account/select-company'),
      })
    },
    gotoLogin() {
      this.isLogin = false
      this.signout()
      this.removeUserProfile()
      // this.$router.push({ path: this.$i18n.path('login') })
      window.location.href = this.$i18n.path('login')
    },
    gotoRegister() {
      this.isLogin = false
      this.signout()
      this.removeUserProfile()
      // this.$router.push({ path: this.$i18n.path('register') })
      window.location.href = this.$i18n.path('register')
    },
    redirectHomePage() {
      this.keyword = ''
      // this.$router.push({ path: this.$i18n.path('') })
    },
    logOut() {
      this.$nextTick(() => {
        this.isLogin = true
        window.location.reload()
        this.signout()
        this.$setBrazeGuestUser()
        this.removeUserProfile()
        this.removeOTPVerify()
        this.saveRouteHistory('')
      })

      // window.location.href = '/'
      // window.location.reload()
      // this.$router.push({ path: this.$i18n.path('') })
      // window.location.href = this.$i18n.locale === 'th' ? '/' : '/en/'

      // this.isLogin = false
      // this.signout()
      // this.removeUserProfile()
      // this.$router.push({ path: this.$i18n.path('login') })
    },
    // gotoRegister() {
    //   this.isLogin = false
    //   this.signout()
    //   this.removeUserProfile()
    //   this.$router.push({ path: this.$i18n.path('register-new') })
    // },
    // redirectHomePage() {
    //   this.keyword = ''
    //   this.$router.push({ path: this.$i18n.path('') })
    // },
    // logOut() {
    //   this.isLogin = false
    //   this.signout()
    //   this.removeUserProfile()
    //   this.saveRouteHistory('')
    //   // window.location.reload()
    //   // this.$router.push({ path: this.$i18n.path('biz') })
    //   window.location.href = `${this.$i18n.path('biz')}`
    // },
    eventCart() {
      if (this.isUser) {
        this.selectNav = this.selectNav === 'cart' ? '' : 'cart'
        this.getCartItem()
      } else {
        this.gotoLogin()
      }
    },
    onChangeLanguage(language) {
      // this.lang = language
      this.$router.push(
        `${
          language === 'th'
            ? this.$route.fullPath.replace(/^\/[^\/]+/, '')
            : this.$route.fullPath.includes('/en/')
            ? this.$route.fullPath
            : `/en${this.$route.fullPath}`
        }`
      )
      this.getNotification()
    },
    // onSearchProducts(keyword) {
    //   if (keyword.length >= 3) {
    //     this.showSearchResult = true
    //   } else {
    //     this.showSearchResult = false
    //   }
    // },
    handleFocusOut() {
      let _this = this
      this.setTimeoutResult = setTimeout(function () {
        _this.showSearchResult = false
        _this.$nuxt.$emit('onActiveOverlay', false)
      }, 200)
    },
    removeTimeout(data) {
      clearTimeout(data)
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
  },
}
