
import InputText from '@/components/share/input-text.vue'
import { mapState, mapActions } from 'vuex'
import jwtDecode from 'jwt-decode'
import modalWarning from '@/components/modal/modalWarning.vue'
export default {
  layout: 'blank-nav',
  name: 'CheckTax',
  components: {
    'input-text': InputText,
    'modal-warning': modalWarning,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      reloadResgiter: true,
      hasAccount: false,
      taxId: '',
      accountSelected: '',
      accountType: '',
      accountList: [],
      branchId: '',
      isDuplicateBranch: false,
      modalWarning: false,
      warningMessages: [],
    }
  },
  computed: {
    ...mapState('user', ['userProfile', 'profileType']),
    ...mapState('authen', ['accessToken', 'openId']),
    getUserProfile() {
      // console.log('userProfile', this.userProfile)
      return this.userProfile
    },
    decodeAccessToken() {
      if (this.$utils.isValid(this.accessToken)) {
        return jwtDecode(this.accessToken)
      } else return ''
    },
    warningDuplicateBranchId() {
      let msg = this.$t(
        'account-new-register-check-tax.table.warningDuplicateBranchId'
      )
      return msg.replace('{branchId}', this.branchId)
    },
  },
  watch: {
    branchId: function (val) {
      this.branchId = val.replace(/[^0-9/.\s]/g, '')
      this.isDuplicateBranch = this.accountList.filter(
        (q) => q.branch_id === this.branchId
      ).length
        ? true
        : false
    },
    accountSelected: function (val) {
      if (val === 'newCorporate')
        this.$nextTick(() => this.$refs.inputBranchId.focus())
    },
  },
  mounted() {
    localStorage.removeItem('tax_id')
    this.checkAccountActivate()
  },
  methods: {
    ...mapActions('authen', ['setAuthToken']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', ['setUserInfo', 'setUserProfile']),

    onSubmit(isCorporate) {
      if (isCorporate) {
        this.$refs.checkTaxForm.validate().then((result) => {
          if (result) {
            this.searchCustomer(isCorporate)
          }
        })
      } else {
        this.$refs.checkTaxForm.reset()
        this.searchCustomer(isCorporate)
      }
    },
    async searchCustomer(isCorporate) {
      // console.log('taxId', this.taxId)
      // const params = new URLSearchParams()
      // params.append('email', this.decodeAccessToken.email)
      // if (isCorporate) {
      //   params.append('accountType', 'Corporate')
      //   params.append('taxId', this.taxId)
      // } else params.append('accountType', 'Personal')
      const data = JSON.stringify({
        // accountId: this.getUserProfile.id,
        // offlineId: this.accountSelected,
        email: this.decodeAccessToken.email,
        accountType: isCorporate ? 'Corporate' : 'Personal',
        taxId: this.taxId,
      })
      try {
        const result = await this.$profileProvider.searchCustomerList(data)
        if (result.status === 201) {
          if (result.data.length) {
            this.hasAccount = true
            this.accountList = result.data
            this.accountType = isCorporate ? 'Corporate' : 'Personal'
            this.accountList.forEach((item) => {
              item.remark = ''
              if (item.is_duplicate_account)
                item.remark =
                  'รหัสลูกค้านี้มีการใช้งานบน online อยู่แล้ว กรุณาติดต่อเจ้าหน้าที่'
              else {
                if (isCorporate) {
                  if (item.tax_id === this.taxId)
                    item.remark = `ข้อมูลตรงกับเลขประจำตัวผู้เสียภาษีของคุณ`
                  if (item.email === this.decodeAccessToken.email) {
                    if (item.remark.length)
                      item.remark += `<br>ข้อมูลตรงกับอีเมลของคุณ`
                    else item.remark = `ข้อมูลตรงกับอีเมลของคุณ`
                  }
                } else {
                  item.remark = `ข้อมูลตรงกับอีเมลของคุณ`
                }
              }
            })
          } else {
            this.hasAccount = false
            // this.$router.push({ name: 'account-new-register-address' })
            this.checkRedirect(isCorporate)
          }
        } else if (result.status === 404) {
          this.hasAccount = false
          // this.$router.push({ name: 'account-new-register-address' })
          this.checkRedirect(isCorporate)
        } else {
          this.hasAccount = false
          this.checkRedirect(isCorporate)
        }
        console.log('result', result)
      } catch (error) {
        console.error('Error searching customer list:', error.response || error)
      }
    },
    async onSelectedAccount() {
      if (this.$utils.isValid(this.accountSelected)) {
        switch (this.accountSelected) {
          case 'newPersonal':
            this.checkRedirect(false)
            break
          case 'newCorporate':
            this.$refs.validateBrachId.validate().then((result) => {
              if (result) this.checkRedirect(true)
            })
            break
          default:
            this.updateUserProfile()
            break
        }
      } else {
        this.$bvToast.toast('กรุณาเลือกบัญชีหรือสร้างบัญชีใหม่', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        })
      }
    },
    async updateUserProfile() {
      console.log(
        'email: ',
        this.decodeAccessToken.email,
        'onlineAccountId: ',
        this.getUserProfile.id,
        'accountSelected: ',
        this.accountSelected
      )
      let data = JSON.stringify({
        accountId: this.getUserProfile.id,
        offlineId: this.accountSelected,
        email: this.decodeAccessToken.email,
      })
      await this.$profileProvider.updateUserProfile(data).then(
        async (result) => {
          console.log('result', result)
          if (result.status === 201) {
            if (Object.keys(this.$route.query).length) {
              if (this.$utils.isValid(this.$route.query.callback)) {
                if (this.$route.query.callback === 'checkout/shipping')
                  this.createOrderTemporary('checkout/shipping')
                else {
                  await this.getUserData()
                }
              } else this.$router.push({ path: this.$i18n.path('') })
            } else this.$router.push({ path: this.$i18n.path('') })
          } else if (result.status !== 412) {
            this.warningMessages = []
            this.warningMessages.push({
              message: {
                th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
              },
            })
            this.modalWarning = true
          }
        },
        (errors) => {
          console.log('error', errors.response)
        }
      )
    },
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId, false)
        .then((result) => {
          this.userData = result.data

          if (!this.userData.is_verify) {
            // this.$router.push({ path: this.$i18n.path('otp') })
            this.onRequestOTP(this.userData.email, 'LoginVerify')
          } else {
            let tempUserData = Object.assign({}, result.data)
            delete tempUserData['accounts']
            delete tempUserData['companies']
            // delete tempUserData['phones']
            delete tempUserData['create_info']
            delete tempUserData['update_info']
            this.setUserInfo({ payload: tempUserData })
            if (
              this.userProfile !== undefined &&
              Object.keys(this.userProfile).length
            ) {
              // case has seleced profile
              // console.log('this.profileType', this.profileType)
              if (this.profileType === 'standard') {
                let account = result.data.accounts.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('account', account)
                this.setUserProfile({
                  payload: account,
                  profileType: 'standard',
                })
              } else if (this.profileType === 'eprocurement') {
                let company = result.data.companies.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('company', company)
                this.setUserProfile({
                  payload: company,
                  profileType: 'eprocurement',
                })
                if (
                  this.$utils.isPasswordExpire(
                    this.userData.companies[0].password_expire
                  )
                )
                  // this.$router.push({
                  //   path: this.$i18n.path('changepassword'),
                  // })
                  this.onRequestOTP(this.userData.email, 'ChangePassword')
              }
              this.$nextTick(() => {
                this.$router.push({
                  path: this.$i18n.path(this.$route.query.callback),
                })
              })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async createOrderTemporary(redirectPath) {
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        reqesterline_id: '',
        companies_budget_control_id: '',
        voucher_no: this.$utils.isValid(this.$route.query.voucher_no)
          ? this.$route.query.voucher_no
          : '',
      })
      const result = await this.$shoppingCartProvider.createOrderTemporary(
        apiData
      )
      if (result.status === 201) {
        await this.getUserData()
        this.$router.push({
          path: redirectPath,
          name:
            this.$i18n.locale === 'th'
              ? 'checkout-shipping-tempId'
              : 'lang-checkout-shipping-tempId',
          params: { tempId: result.data.temp_id },
        })
      } else if (result.status !== 412) {
        this.$router.push({
          path: this.$i18n.path('cart'),
        })
      }
    },
    checkRedirect(isCorporate) {
      localStorage.setItem('tax_id', btoa(this.taxId))
      if (
        Object.keys(this.$route.query).length &&
        this.$utils.isValid(this.$route.query.callback)
      ) {
        this.$router.push({
          // path: this.$i18n.path(
          //   `account/new-register/address?${
          //     isCorporate
          //       ? `accountType=${btoa('Corporate')}&branchId=${btoa(
          //           this.branchId
          //         )}&taxId=${btoa(this.taxId)}`
          //       : `accountType=${btoa('Personal')}&branchId=`
          //   }&callback=${this.$route.query.callback}`
          // ),
          path: this.$i18n.path(
            `account/new-register/address?${
              isCorporate
                ? `accountType=${btoa('Corporate')}&branchId=${btoa(
                    this.branchId
                  )}`
                : `accountType=${btoa('Personal')}&branchId=`
            }&callback=${this.$route.query.callback}`
          ),
        })
      } else {
        this.$router.push({
          // path: this.$i18n.path(
          //   `account/new-register/address?${
          //     isCorporate
          //       ? `accountType=${btoa('Corporate')}&branchId=${btoa(
          //           this.branchId
          //         )}&taxId=${btoa(this.taxId)}`
          //       : `accountType=${btoa('Personal')}&branchId=`
          //   }`
          // ),
          path: this.$i18n.path(
            `account/new-register/address?${
              isCorporate
                ? `accountType=${btoa('Corporate')}&branchId=${btoa(
                    this.branchId
                  )}`
                : `accountType=${btoa('Personal')}&branchId=`
            }`
          ),
        })
      }
    },
    checkAccountActivate() {
      if (this.userProfile.is_activate) {
        this.$router.push({
          path: this.$i18n.path(this.$route.query.callback),
        })
      }
    },
    async onRequestOTP(email, type) {
      console.log('onRequestOTP', email)
      let apiData = JSON.stringify({
        reference: {
          by_key: email,
          by_token: '',
          token_type: type,
        },
        channel: 'Email',
        is_skip_force_request: true,
      })
      console.log('apiData', apiData)
      const responsed = await this.$authenProvider.requestOTPByChannel(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        const dataResponse = responsed.data
        console.log('dataResponse', dataResponse)
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
    },
  },
}
