
export default {
  name: 'confirmInformationModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    sumbitBtnWording: {
      type: String,
      default: 'Submit',
    },
    modalSize: {
      type: String,
      default: 'md',
    },
  },
  computed: {},
  watch: {
    show(value) {
      if (value) {
        this.$refs.confirmInformationModal.show()
      }
    },
  },
  methods: {
    onClose() {
      this.$refs.confirmInformationModal.hide()
      this.$emit('hidden')
    },
    async onSubmit() {
      this.$emit('confirm')
      this.$refs.confirmInformationModal.hide()
    },
  },
}
