
import InputText from '@/components/share/input-text.vue'

import Consent from '@/components/share/consent.vue'
const Cookie = process.client ? require('js-cookie') : undefined
import jwtDecode from 'jwt-decode'
export default {
  layout: 'blank-nav',
  name: 'NewPassword',
  components: {
    'input-text': InputText,
    consent: Consent,
  },
  data() {
    return {
      reloadPassword: true,
      dataForNewPassword: {
        password: '',
        rePassword: '',
      },
      verifyAccessToken: '',
      userOpenId: '',
      email: '',
      action: '',
      token_type: '',
    }
  },
  computed: {
    confirmation() {
      return this.dataForNewPassword.password !==
        this.dataForNewPassword.rePassword
        ? true
        : false
    },
  },
  watch: {
    // 'dataForNewPassword.rePassword': function (value) {
    //   if (
    //     value !== this.dataForNewPassword.password &&
    //     this.dataForNewPassword.password !== ''
    //   ) {
    //     this.confirmation = true
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'รหัสผ่าน'
    //   } else {
    //     this.confirmation = false
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'ยืนยันรหัสผ่าน'
    //   }
    // },
    // 'dataForNewPassword.password': function (value) {
    //   if (
    //     value !== this.dataForNewPassword.rePassword &&
    //     this.dataForNewPassword.rePassword !== ''
    //   ) {
    //     this.confirmation = true
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'รหัสผ่าน'
    //   } else {
    //     this.confirmation = false
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'ยืนยันรหัสผ่าน'
    //   }
    // },
  },
  async mounted() {
    if (this.$utils.isValid(this.$route.query.id)) {
      this.DecodeJWT()
    } else this.$router.push({ path: this.$i18n.path('forgotpassword') })
  },
  methods: {
    async DecodeJWT() {
      try {
        const decoded = jwtDecode(this.$route.query.id)
        console.log('decoded', decoded)
        if (this.$utils.isValid(decoded.identity)) {
          this.email = decoded.identity
        }
        if (this.$utils.isValid(decoded.reference_key)) {
          if (decoded.reference_key === 'Invite') {
            this.action = 'Invite'
          }
          this.token_type = decoded.reference_key
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async onSubmit() {
      this.$refs.passwordVerifyForm.validate().then(async (result) => {
        if (result) {
          if (this.action === 'Invite') {
            await this.createConsentStatus()
          }
          await this.setNewPassword()
        }
      })
    },
    async setNewPassword() {
      const password = this.$utils.cryptoJS(this.dataForNewPassword.password)
      let apiData = Object.assign({
        password: password,
        token_type: this.token_type,
      })
      const otp_prove_token = this.$route.query.id
      const response = await this.$authenProvider.updatePasswordV2(
        otp_prove_token,
        apiData
      )
      console.log('updatePasswordV2', response)
      if (response.status === 204) {
        this.$bvToast.toast('เปลี่ยนรหัสผ่านสำเร็จ', {
          title: `Success`,
          variant: 'success',
          solid: true,
        })
        // this.$router.push({ path: this.$i18n.path('/') })
        window.location.href = this.$i18n.path('login')
      } else if (response.status === 400) {
        this.$bvToast.toast('เปลี่ยนรหัสผ่านไม่สำเร็จ', {
          title: `Error`,
          variant: 'danger',
          solid: true,
        })
      }
    },

    async createConsentStatus() {
      let payload = JSON.stringify({
        email: this.email,
        user_open_id: '',
        consent_status: this.consentStatus,
      })
      console.log('createConsentStatus', payload)
      this.$profileProvider.createUserConsentStatus(payload)
    },
  },
}
