
import InputText from '@/components/share/input-text.vue'
import { mapGetters } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  layout: 'blank-nav',
  name: 'ChangePassword',
  components: {
    'input-text': InputText,
  },
  data() {
    return {
      reloadPassword: true,
      dataForNewPassword: {
        oldPassword: '',
        password: '',
        rePassword: '',
      },
      isChangePasswordFail: false,
      dismissSecs: 5,
    }
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'accessToken',
      'isAuthenticated',
      'isGuest',
    ]),
    ...mapGetters(['routeHistory']),
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
    confirmation() {
      return (
        this.dataForNewPassword.password !== this.dataForNewPassword.rePassword
      )
    },
  },
  watch: {},
  async mounted() {
    if (!this.isUser) {
      console.log('isUser : ', this.isUser)
      this.gotoLogin()
    }
  },
  methods: {
    gotoLogin() {
      this.$router.push({ path: this.$i18n.path('login') })
    },
    showFailBox() {
      this.isChangePasswordFail = this.dismissSecs
    },
    countDownChangedFail(dismissCountDown) {
      this.isChangePasswordFail = dismissCountDown
    },
    async onSubmit() {
      this.$refs.passwordVerifyForm.validate().then(async (result) => {
        if (result) {
          await this.setNewPassword()
        }
      })
    },
    async setNewPassword() {
      const oldPassword = this.$utils.cryptoJS(
        this.dataForNewPassword.oldPassword
      )

      const password = this.$utils.cryptoJS(this.dataForNewPassword.password)
      let apiData = JSON.stringify({
        // open_id: this.openId,
        old_password: oldPassword,
        new_password: password,
        otp_prove_token: this.$route.query.id,
      })
      console.log('updatePassword : ', apiData)
      const result = await this.$profileProvider.updatePasswordV2(apiData)
      if (result.status === 204 || result.status === 201) {
        console.log('routeApproveRedirect', Cookie.get('previousPage'))
        console.log('routeHistory', this.routeHistory)
        let previousPage = Cookie.get('previousPage')
        if (previousPage) {
          this.$router.push({ path: this.$i18n.path(previousPage) })
          // this.clearCookies()
        } else if (this.$utils.isValid(this.routeHistory)) {
          window.location.href = this.routeHistory
        } else {
          window.location.href = this.$i18n.path('')
        }
      } else {
        this.isChangePasswordFail = true
        this.showFailBox()
      }
    },
  },
}
