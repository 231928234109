
import InputText from '@/components/share/input-text.vue'
import OTPModal from '@/components/modal/otpModal.vue'
import modalWarning from '@/components/modal/modalWarning'
import { mapActions } from 'vuex'

export default {
  layout: 'blank-nav',
  name: 'RegisterNew',
  components: {
    'input-text': InputText,
    'otp-modal': OTPModal,
    'modal-warning': modalWarning,
  },
  data() {
    return {
      showOTPModal: false,
      reloadResgiter: true,
      duplicateEmailFlag: false,
      dataForRegister: {
        email: '',
        name: '',
        last_name: '',
        password: '',
      },
      openId: '',
      consentStatus: false,
      warningMessages: [],
      modalWarning: false,
      recaptChaErr: false,
      coverImg: {},
    }
  },
  async asyncData({ route, app, store, $axios }) {
    let coverImg = {}
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
      'Sale-Method-Id': 89,
    }
    const params = {
      page_type: 'register',
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    // axios
    try {
      const responsed = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`
        ),
        {
          params,
          headers,
        }
      )
      console.log('responsed', responsed)
      if (responsed.status === 200) {
        coverImg = { ...responsed.data }
      }
    } catch (e) {
      console.log('catch', e)
    }

    return {
      coverImg,
    }
  },
  // created() {
  //   this.getฺBannerPageData()
  // },
  computed: {
    warningDuplicateEmail() {
      let msg = this.$t('register-new.warningDuplicateEmail')
      return msg.replace('{dataForRegister.email}', this.dataForRegister.email)
    },
  },
  beforeDestroy() {
    // this.$nuxt.$off('onOTP', () => {})
  },
  methods: {
    ...mapActions('authen', ['setAuthToken', 'signout']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', [
      'setUserProfile',
      'removeUserProfile',
      'setUserInfo',
    ]),
    // openOTP() {
    //   this.showOTPModal = true
    // },
    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
    async checkEmail() {
      console.log('before validate email')
      if (this.dataForRegister.email.includes('@')) {
        const validateResult = this.$refs['อีเมล'].validationInput()
        console.log('validateResult', validateResult)
        if (validateResult) {
          const responsed = await this.$profileProvider.checkEmail(
            this.dataForRegister.email
          )
          console.log('responsed', responsed)
          if (responsed.status === 200) this.duplicateEmailFlag = true
          else if (responsed.status === 404) this.duplicateEmailFlag = false
        }
      }
    },
    validateBeforeSubmit() {
      this.$refs.registerForm.validate().then((result) => {
        if (result) {
          this.onReCaptCha()
        }
      })
    },
    async onReCaptCha() {
      this.recaptChaErr = false
      try {
        const token = await this.$recaptcha.getResponse()
        // send token to register()
        this.register(token)
        console.log('Token Response: ', token)

        // reset recaptcha
        await this.$recaptcha.reset()
      } catch (error) {
        this.recaptChaErr = true
        console.log('Token error:', error)
      }
    },
    async register(token) {
      console.log('Register: ', token)
      const password = this.$utils.cryptoJS(this.dataForRegister.password)
      let apiData = Object.assign({}, this.dataForRegister)
      apiData.password = password
      apiData.token_recaptcha = token
      apiData.consent_status = this.consentStatus
      console.log('registerBody', apiData)
      const responsed = await this.$profileProvider.register(apiData)
      console.log('responsed: ', responsed)
      if (responsed.status === 201) {
        await this.autoSignin(apiData)
        // this.$router.push({ path: this.$i18n.path('biz') })
        //   // this.setAuthToken(responsed.data)
        //   // this.setCartId(responsed.data.openId)
        //   // this.$router.push({ path: this.$i18n.path('verify') })
      }
      if (responsed.status === 409) {
        this.showOTPModal = true
        console.log('splitMsg', responsed.data)
        this.openId = responsed.data.reference_id_1
        this.createConsentStatus(this.openId)
      } else if (responsed.status === 412) {
        this.warningMessages = []
        let msg = this.$t('register-new.warningDuplicateEmail')
        msg = msg.replace('{dataForRegister.email}', this.dataForRegister.email)
        this.warningMessages.push({
          message: {
            th: msg,
            en: msg,
          },
        })
        this.modalWarning = true
      } else if (responsed.status === 400) {
        console.log(responsed)
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: responsed.data.message.th,
            en: responsed.data.message.en,
          },
        })
        this.modalWarning = true
      }
      // this.$_clearForm('register', 'reloadResgiter')
    },
    async autoSignin(userData) {
      let loginData = JSON.stringify({
        email: userData.email,
        password: userData.password,
      })
      const responsed = await this.$authenProvider.signInBasic(loginData)
      if (responsed.status === 201) {
        // insider
        // let dataOptin = {
        //   page: 'Other',
        //   uuid: responsed.data.openId,
        //   emailOptin: this.consentStatus,
        //   name: this.dataForRegister.name,
        //   surname: this.dataForRegister.last_name,
        //   email: userData.email,
        // }
        // await this.$_insiderEmailOptin(dataOptin)

        console.log('responsed: ', responsed)
        this.createConsentStatus(responsed.data.openId)
        await this.setCartId(responsed.data.openId)
        await this.setAuthToken(responsed.data)
        await this.getUserData(responsed.data.openId)
      }
    },
    async getUserData(openId) {
      var vm = this
      await this.$profileProvider
        .getUserDataById(openId)
        .then((result) => {
          console.log(result)
          let userData = result.data
          // console.log('this.userProfile', this.userProfile)
          if (!userData.is_verify)
            this.$router.push({ path: this.$i18n.path('verify') })
          else {
            if (userData.accounts.length || userData.companies.length) {
              if (
                userData.accounts.length === 1 &&
                userData.companies.length === 0
              ) {
                // use account
                vm.setUserProfile({
                  payload: userData.accounts[0],
                  profileType: 'standard',
                })
                this.$router.push({
                  path: this.$i18n.path(''),
                })
              } else if (
                userData.accounts.length === 0 &&
                userData.companies.length === 1
              ) {
                //use company
                vm.setUserProfile({
                  payload: userData.companies[0],
                  profileType: 'eprocurement',
                })
                if (
                  this.$utils.isPasswordExpire(
                    userData.companies[0].password_expire
                  )
                ) {
                  this.$router.push({
                    path: this.$i18n.path('changepassword'),
                  })
                } else {
                  let tempUserData = Object.assign({}, result.data)
                  delete tempUserData['accounts']
                  delete tempUserData['companies']
                  delete tempUserData['create_info']
                  delete tempUserData['update_info']
                  this.setUserInfo({ payload: tempUserData })
                  // braze
                  this.$setBrazeUser(
                    this.$store.getters['user/userInfo'],
                    this.$store.getters['user/profileType'],
                    this.$store.getters['user/userProfile']
                  )
                  // gtm register
                  this.$gtm.push({
                    event: 'register',
                    ...this.$store.getters['user/userInfoTrack'],
                  })
                  // this.$router.push({
                  //   path: this.$i18n.path(''),
                  // })
                  window.location.href = this.$i18n.path('')
                }
              } else {
                //select account || company
                this.$router.push({
                  path: this.$i18n.path('account/select-company'),
                })
              }
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    createConsentStatus(openId) {
      let payload = JSON.stringify({
        email: this.dataForRegister.email,
        user_open_id: openId,
        consent_status: this.consentStatus,
      })
      this.$profileProvider.createUserConsentStatus(payload)
    },
    gotoTermsAndConditions() {
      this.$router.push({
        path: this.$i18n.path('activity/termsandconditions'),
      })
    },
    gotoPrivacy() {
      this.$router.push({ path: this.$i18n.path('activity/privacy') })
    },
    // async getฺBannerPageData() {
    //   // let params = new URLSearchParams()
    //   // params.append('page_type', 'login')
    //   // const responsed = await this.$profileProvider.getฺBannerPageData(params)
    //   // if (responsed.status === 200) {
    //   //   this.coverImg = { ...responsed.data }
    //   // }
    //   const headers = {
    //     Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    //     'Sale-Method-Id': 89,
    //     //   this.$store.state.user.profileType === 'eprocurement' ? 6 : 89,
    //   }
    //   const params = {
    //     page_type: 'register',
    //   }
    //   const http = process.env.NUXT_ENV_BASE_URL_HTTP
    //   // axios
    //   try {
    //     const responsed = await this.$axios.get(
    //       encodeURI(
    //         `${http}${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`
    //       ),
    //       {
    //         params,
    //         headers,
    //       }
    //     )
    //     console.log('responsed', responsed)
    //     if (responsed.status === 200) {
    //       this.coverImg = { ...responsed.data }
    //     }
    //   } catch (e) {
    //     console.log('catch', e)
    //   }
    // },
  },
}
