
import InputText from '@/components/share/input-text.vue'
import OTPModal from '@/components/modal/otpModal.vue'
import modalWarning from '@/components/modal/modalWarning'
import { mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  layout: 'blank-nav',
  name: 'RegisterNew',
  components: {
    'input-text': InputText,
    'otp-modal': OTPModal,
    'modal-warning': modalWarning,
  },
  data() {
    return {
      showOTPModal: false,
      reloadResgiter: true,
      duplicateEmailFlag: false,
      dataForRegister: {
        email: '',
        name: '',
        last_name: '',
        password: '',
      },
      openId: '',
      consentStatus: false,
      warningMessages: [],
      modalWarning: false,
      recaptChaErr: false,
      coverImg: {},
      userData: {},
    }
  },
  async asyncData({ route, app, store, $axios }) {
    let coverImg = {}
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
      'Sale-Method-Id': 89,
    }
    const params = {
      page_type: 'register',
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    // axios
    try {
      const responsed = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`
        ),
        {
          params,
          headers,
        }
      )
      console.log('responsed', responsed)
      if (responsed.status === 200) {
        coverImg = { ...responsed.data }
      }
    } catch (e) {
      console.log('catch', e)
    }

    return {
      coverImg,
    }
  },
  // created() {
  //   this.getฺBannerPageData()
  // },
  computed: {
    warningDuplicateEmail() {
      let msg = this.$t('register-new.warningDuplicateEmail')
      return msg.replace('{dataForRegister.email}', this.dataForRegister.email)
    },
  },
  beforeDestroy() {
    // this.$nuxt.$off('onOTP', () => {})
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
    validateBeforeSubmit() {
      this.$refs.registerForm.validate().then((result) => {
        if (result) {
          this.onReCaptCha()
        }
      })
    },
    async onReCaptCha() {
      this.recaptChaErr = false
      try {
        const token = await this.$recaptcha.getResponse()
        // send token to register()
        this.register(token)
        // console.log('Token Response: ', token)

        // reset recaptcha
        await this.$recaptcha.reset()
      } catch (error) {
        this.recaptChaErr = true
        console.log('Token error:', error)
      }
    },
    async register(token) {
      console.log('Register')
      const password = this.$utils.cryptoJS(this.dataForRegister.password)
      const apiData = JSON.stringify({
        email: this.dataForRegister.email,
        password: password,
        name: this.dataForRegister.name,
        last_name: this.dataForRegister.last_name,
        token_recaptcha: token,
        consent_status: this.consentStatus,
      })
      const responsed = await this.$profileProvider.registerV2(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        console.log('responsed: ', responsed)
        const dataResponse = responsed.data
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
      if (responsed.status === 409) {
        if (responsed?.data?.code === 'C048') {
          if (responsed?.data?.message) {
            this.warningMessages = []

            this.warningMessages.push({
              message: {
                th: responsed?.data?.message.th,
                en: responsed?.data?.message.en,
              },
            })
            this.modalWarning = true
          }
        }
      } else if (responsed.status === 412) {
        this.duplicateEmailConflict()
      } else if (responsed.status === 400) {
        console.log(responsed)
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: responsed.data.message.th,
            en: responsed.data.message.en,
          },
        })
        this.modalWarning = true
      }
      this.$_clearForm('register', 'reloadResgiter')
    },
    duplicateEmailConflict() {
      this.warningMessages = []
      let msg = this.$t('register-new.warningDuplicateEmail')
      msg = msg.replace('{dataForRegister.email}', this.dataForRegister.email)
      this.warningMessages.push({
        message: {
          th: msg,
          en: msg,
        },
      })
      this.modalWarning = true
    },
    gotoTermsAndConditions() {
      this.$router.push({
        path: this.$i18n.path('activity/termsandconditions'),
      })
    },
    gotoPrivacy() {
      this.$router.push({ path: this.$i18n.path('activity/privacy') })
    },
  },
}
