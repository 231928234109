
import InputText from '@/components/share/input-text.vue'
import ConfirmInformationModal from '@/components/modal/confirmInformationModal.vue'
export default {
  layout: 'blank-nav',
  name: 'ForgetPassword',
  components: {
    'input-text': InputText,
    'confirm-information-modal': ConfirmInformationModal,
  },
  data() {
    return {
      dataForReset: {
        email: '',
      },
      modal: {
        isShow: false,
        title: '',
        message: '',
        sumbitBtnWording: '',
        defaultMessage: {
          th: 'หากที่อยู่อีเมลนี้มีอยู่ในระบบของเรา เราจะส่งลิงก์สำหรับรีเซ็ตรหัสผ่านให้ภายในไม่กี่นาที',
          en: 'If the email address is known to us, we’ll send a password reset link in a few minutes.',
        },
      },
    }
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {
    async onSubmit() {
      this.$refs.emailVerifyForm.validate().then(async (result) => {
        if (result) {
          await this.forgotPassword()
        }
      })
    },
    async forgotPassword() {
      let apiData = JSON.stringify({
        email: this.dataForReset.email,
      })
      const responsed = await this.$authenProvider.forgotPasswordV2(apiData)

      console.log('forgotPassword', responsed)
      if (responsed.status === 201) {
        this.modal.title = ''
        this.modal.message =
          responsed?.data?.message?.[this.$i18n.locale] ??
          this.modal.defaultMessage[this.$i18n.locale] ??
          ''

        this.modal.isShow = true
      } else if (responsed.status === 429) {
        this.modal.title = ''
        this.modal.message =
          responsed?.data?.message?.[this.$i18n.locale] ??
          this.modal.defaultMessage[this.$i18n.locale] ??
          ''
        this.modal.isShow = true
      }
      this.dataForReset.email = ''
      this.$refs.emailVerifyForm.reset()
    },
  },
}
