
import asideComponent from '@/components/template/aside-user.vue'
import { mapGetters } from 'vuex'

export default {
  layout: 'main',
  name: 'UserCreate',
  components: {
    'aside-component': asideComponent,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      error_messange: '',
      isPhone: false,
      user: {
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: true,
        default_language: 'TH',
      },
      create: {
        account_id: '',
        email: '',
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: '',
          value: '',
        },
        mobile: {
          type: '',
          value: '',
        },
        is_user_active: true,
        head_user_open_id: '',
        system: 'Online e-Procurement',
        default_language: '',
      },
      duplicateEmailFlag: false,
      isenable: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    requiredIsPhone() {
      return this.create.phone.value === '' && this.create.mobile.value === ''
    },
  },
  watch: {
    'create.phone.value': function (val) {
      if (val !== '') {
        this.isPhone = false
      }
    },
    'create.phone.extension': function (val) {
      if (val === '') {
        this.isPhone = false
      }
    },
  },
  mounted() {},
  methods: {
    validateCreateSubmit() {
      this.$refs.createUserForm.validate().then((result) => {
        if (result) {
          if (this.userProfile.account_channel === 'standard') {
            if (
              this.create.phone.value === '' &&
              this.create.phone.extension !== ''
            ) {
              this.isPhone = true
            } else {
              this.create.account_id = this.userProfile.id
              this.createUserAccountData()
              this.isPhone = false
            }
          }
        }
      })
    },
    async createUserAccountData() {
      this.create.default_language = this.user.default_language.toLowerCase()
      if (this.create.phone.value !== '') {
        this.create.phone.type = 'Phone'
      }
      if (this.create.mobile.value !== '') {
        this.create.mobile.type = 'Mobile'
      }
      console.log('object', this.create)
      await this.$profileProvider.createUserAccountDataByIdV2(this.create).then(
        (result) => {
          if (result.status === 201) {
            let data = result.data
            this.onCancel()
            console.log('createUserAccountDataByIdV2', data)
          }
        },
        (error) => {
          console.log('createUserAccountDataByIdV2', error.responsed)
        }
      )
    },
    onCancel() {
      this.$router.push({
        path: this.$i18n.path('account/user'),
      })
    },
    async checkEmail() {
      this.error_messange = ''
      this.isenable = false
      this.user = {
        first_name: {
          th: '',
          en: '',
        },
        last_name: {
          th: '',
          en: '',
        },
        phone: {
          extension: '',
          type: 'Phone',
          value: '',
        },
        mobile: {
          type: 'Mobile',
          value: '',
        },
        is_user_active: true,
        default_language: 'TH',
      }
      let params = new URLSearchParams()
      params.append('email', this.create.email)
      params.append('useropenid', this.openId)
      params.append('account_channel', this.profileType)

      if (this.create.email.includes('@')) {
        const validateResult = this.$refs['อีเมล'].validationInput()
        console.log('validateResult', validateResult)

        if (validateResult) {
          const responsed = await this.$profileProvider.checkCompanyEmail(
            this.userProfile.id,
            params
          )
          if (responsed.status === 200) {
            console.log('responsed', responsed.data)
            let data = responsed.data
            if (!data.isuser) {
              this.duplicateEmailFlag = true
              this.error_messange = data.messange
            } else {
              this.isenable = true
              this.user = {
                first_name: data.first_name,
                last_name: data.last_name,
                phone:
                  data.phone !== null
                    ? data.phone.find((a) => a.type === 'Phone')
                    : undefined,
                mobile:
                  data.phone !== null
                    ? data.phone.find((a) => a.type === 'Mobile')
                    : undefined,
                is_user_active: true,
                default_language: data.default_language.toUpperCase(),
              }
              console.log('this.user', this.user)
            }
          }
          if (responsed.status === 404) {
            this.duplicateEmailFlag = false
            this.error_messange = this.$i18n.t(`account-user-create.errormsg`)
          }
        }
      }
    },
    onChangeLanguage(lang) {
      this.user.default_language = lang
    },
  },
}
