
import InputText from '@/components/share/input-text.vue'
import modalWarning from '@/components/modal/modalWarning'
import ConfirmInformationModal from '@/components/modal/confirmInformationModal.vue'
import { mapGetters, mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  layout: 'blank-nav',
  name: 'LoginPage',
  components: {
    'input-text': InputText,
    'modal-warning': modalWarning,
    'confirm-information-modal': ConfirmInformationModal,
    QrCode: async () => await import('@/components/qr/qr-component.vue'),
  },
  data() {
    return {
      dataForLogin: {
        email: '',
        password: '',
      },
      openId: '',
      checkSave: false,
      consentStatus: false,
      toast: 0,
      warningMessages: [],
      modalWarning: false,
      showQrLogin: false, // เริ่มต้นแสดง QR login
      currentHeader: this.$t('login.header'),
      qr: '',
      loginMethod: 'QR code',
      imageSrc: require('@/assets/images/icons/QR.svg'),
      confirmed: false,
      coverImg: {},
      modal: {
        isShow: false,
        title: '',
        message: '',
        sumbitBtnWording: '',
      },
    }
  },
  async asyncData({ route, app, store, $axios }) {
    let coverImg = {}
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
      'Sale-Method-Id': 89,
    }
    const params = {
      page_type: 'login',
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    // axios
    try {
      const responsed = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`
        ),
        {
          params,
          headers,
        }
      )
      console.log('responsed', responsed)
      if (responsed.status === 200) {
        coverImg = { ...responsed.data }
      }
    } catch (e) {
      console.log('catch', e)
    }

    return {
      coverImg,
    }
  },
  computed: {
    ...mapGetters(['routeHistory']),
    // ...mapGetters('authen', ['openId', 'isAuthenticated', 'isGuest']),
    // isUser() {
    //   return this.isAuthenticated && !this.isGuest
    // },
  },
  // created() {
  //   this.getฺBannerPageData()
  // },
  async mounted() {
    // this.$nuxt.$on('onOTP', (data) => {
    //   console.log(data)
    //   console.log('onOTP')
    // })
    window.scrollTo(0, 0)
    // if (process.client) Cookie.remove('coupons')
  },
  beforeDestroy() {
    // this.$nuxt.$off('onOTP', () => {})
  },
  methods: {
    ...mapActions('authen', ['setAuthToken', 'signout']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', [
      'setUserProfile',
      'removeUserProfile',
      'setUserInfo',
    ]),

    async validateBeforeSubmit() {
      this.$refs.loginForm.validate().then(async (result) => {
        if (result) await this.submitLogin()
      })
    },
    async submitLogin() {
      const password = this.$utils.cryptoJS(this.dataForLogin.password)

      let login = Object.assign({}, this.dataForLogin)
      login.password = password
      const responsed = await this.$authenProvider.signInBasicV2(login)
      if (responsed.status === 201) {
        console.log('responsed: ', responsed)
        if (!responsed.data.isVerify) {
          await this.onRequestOTP(this.dataForLogin.email, 'LoginVerify')
        } else {
          let openId =
            responsed.data.openId == null || responsed.data.openId == undefined
              ? responsed.data.user.info.open_id
              : responsed.data.openId
          console.log('responsed.data.openId', openId)
          await this.setCartId(openId)
          await this.setAuthToken(responsed.data)
          await this.getUserData(openId)
        }

        // gtm
        this.$gtm.push({
          event: 'user',
          ...this.$store.getters['user/userInfoTrack'],
        })
      } else if (responsed.status === 412) {
        if (responsed.data) {
          this.showToast(this.$t('login.labelPreconditionFailed'))
        }
      } else if (responsed.status === 429) {
        this.modal.title = ''
        this.modal.message =
          responsed?.data?.message?.[this.$i18n.locale] ??
          this.modal.defaultMessage[this.$i18n.locale] ??
          ''
        this.modal.isShow = true
      } else {
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
            en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
          },
        })
        this.modalWarning = true
      }
    },
    showToast(message) {
      const id = `error-toast-${this.toast++}`

      const Msg = this.$createElement('div', { class: ['mr-auto'] }, [message])
      const CloseToast = this.$createElement('b-icon-x', {
        class: ['ml-auto'],
        on: { click: () => this.$bvToast.hide(id) },
      })

      this.$bvToast.toast([Msg, CloseToast], {
        id: id,
        toaster: 'b-toaster-top-right error-toast',
        noCloseButton: true,
      })
    },
    async getUserData(openId) {
      await this.$profileProvider
        .getUserDataById(openId)
        .then((result) => {
          console.log('result', result)
          if (result.status === 200) {
            let userData = result.data

            // console.log('this.userProfile', this.userProfile)
            if (!userData.is_verify)
              // this.$router.push({ path: this.$i18n.path('otp') })
              this.onRequestOTP(userData.email, 'LoginVerify')
            else {
              let tempUserData = Object.assign({}, result.data)
              delete tempUserData['accounts']
              delete tempUserData['companies']
              // delete tempUserData['phones']
              delete tempUserData['create_info']
              delete tempUserData['update_info']
              this.setUserInfo({ payload: tempUserData })
              if (userData.accounts.length || userData.companies.length) {
                this.$bvToast.hide()
                if (
                  userData.accounts.length === 1 &&
                  userData.companies.length === 0
                ) {
                  // use account
                  this.setUserProfile({
                    payload: userData.accounts[0],
                    profileType: 'standard',
                  })
                  this.updateLastActive(
                    openId,
                    userData.accounts[0].id,
                    'standard'
                  )

                  // saveRouteHistory
                  console.log('saveRouteHistory', this.routeHistory)
                  if (this.$utils.isValid(this.routeHistory)) {
                    // this.$router.push({
                    //   path: this.routeHistory,
                    // })
                    window.location.href = this.routeHistory
                  } else {
                    // this.$router.push({ path: this.$i18n.path('') })
                    window.location.href = this.$i18n.path('')
                  }
                  // braze
                  this.$setBrazeUser(
                    this.$store.getters['user/userInfo'],
                    this.$store.getters['user/profileType'],
                    this.$store.getters['user/userProfile']
                  )
                } else {
                  //select account || company
                  this.$router.push({
                    path: `${
                      userData.default_language === 'th'
                        ? '/account/select-company'
                        : '/en/account/select-company'
                    }`,
                  })
                }
              } else {
                this.signout()
                this.warningMessages = []
                this.warningMessages.push({
                  message: {
                    th: 'ไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าหน้าที่',
                    en: 'ไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าหน้าที่',
                  },
                })
                this.modalWarning = true
              }
            }
          } else {
            this.warningMessages = []
            this.warningMessages.push({
              message: {
                th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
              },
            })
            this.modalWarning = true
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    createConsentStatus(openId) {
      let payload = JSON.stringify({
        email: this.dataForLogin.email,
        user_open_id: openId,
        consent_status: this.consentStatus,
      })
      this.$profileProvider.createUserConsentStatus(payload)
    },
    async updateLastActive(openId, profileId, accountChannel) {
      let apiData = JSON.stringify({
        profile_id: profileId,
        account_channel: accountChannel,
      })
      await this.$profileProvider.updateUserLastActive(openId, apiData)
    },
    gotoRegister() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('register') })
    },
    gotoTermsAndConditions() {
      this.$router.push({
        path: this.$i18n.path('activity/termsandconditions'),
      })
    },
    gotoPrivacy() {
      this.$router.push({ path: this.$i18n.path('activity/privacy') })
    },
    toggleLoginMethod() {
      this.showQrLogin = !this.showQrLogin // สลับการแสดงผล
      if (this.showQrLogin) {
        this.currentHeader =
          this.$t('login.header') + this.$t('qrCode.text-with')
        this.qr = this.$t('qrCode.text-qr')
        this.loginMethod = this.$t('qrCode.text-password')
        this.imageSrc = require('@/assets/images/icons/password.svg')
      } else {
        this.currentHeader = this.$t('login.header')
        this.qr = ''
        this.loginMethod = this.$t('qrCode.text-qr')
        this.imageSrc = require('@/assets/images/icons/QR.svg')
      }
    },
    async onSuccessfulQR(qrProvedToken) {
      await this.verifyLoginQrCode(qrProvedToken)
    },
    async verifyLoginQrCode(qrProvedToken) {
      let body = {
        qr_proved_token: qrProvedToken,
      }
      const responsed = await this.$authenProvider.verifyQrCode(body)
      if (responsed.status === 201) {
        responsed.data = {
          openId: responsed.data.open_id,
          accessToken: responsed.data.access_token,
          isVerify: responsed.data.is_verify,
          refreshToken: responsed.data.refresh_token,
        }
        console.log('responsed: ', responsed)
        if (!responsed.data.isVerify) {
          await this.onRequestOTP(this.dataForLogin.email, 'LoginVerify')
        } else {
          let openId =
            responsed.data.openId == null || responsed.data.openId == undefined
              ? responsed.data.user.info.open_id
              : responsed.data.openId
          console.log('responsed.data.openId', openId)
          await this.setCartId(openId)
          await this.setAuthToken(responsed.data)
          await this.getUserData(openId)
        }

        // gtm
        this.$gtm.push({
          event: 'user',
          ...this.$store.getters['user/userInfoTrack'],
        })
      } else {
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
            en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
          },
        })
        this.modalWarning = true
      }
    },
    async onRequestOTP(email, type) {
      console.log('onRequestOTP', email)
      let apiData = JSON.stringify({
        reference: {
          by_key: email,
          by_token: '',
          token_type: type,
        },
        channel: 'Email',
        is_skip_force_request: true,
      })
      console.log('apiData', apiData)
      const responsed = await this.$authenProvider.requestOTPByChannel(apiData)
      console.log('responsed', responsed)
      if (responsed.status === 201) {
        const dataResponse = responsed.data
        console.log('dataResponse', dataResponse)
        this.$router.push({
          path: this.$i18n.path(`otp?verify_key=${dataResponse.verify_key}`),
        })
      }
    },
    // async getฺBannerPageData() {
    //   // let params = new URLSearchParams()
    //   // params.append('page_type', 'login')
    //   // const responsed = await this.$profileProvider.getฺBannerPageData(params)
    //   // if (responsed.status === 200) {
    //   //   this.coverImg = { ...responsed.data }
    //   // }
    //   const headers = {
    //     Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    //     'Sale-Method-Id': 89,
    //     //   this.$store.state.user.profileType === 'eprocurement' ? 6 : 89,
    //   }
    //   const params = {
    //     page_type: 'login',
    //   }
    //   const http = process.env.NUXT_ENV_BASE_URL_HTTP
    //   // axios
    //   try {
    //     const responsed = await this.$axios.get(
    //       encodeURI(
    //         `${http}${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`
    //       ),
    //       {
    //         params,
    //         headers,
    //       }
    //     )
    //     console.log('responsed', responsed)
    //     if (responsed.status === 200) {
    //       this.coverImg = { ...responsed.data }
    //     }
    //   } catch (e) {
    //     console.log('catch', e)
    //   }
    // },
  },
}
